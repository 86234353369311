import { UserTestId } from '../models/user-test/user-test';

export enum UserTestIdList {
  COLOCARE_TEST_LABCORP_ID = '5ed511872c4e3700195bf293', // Added support for both LabCorp and Quest ColoCare tests
  COLOCARE_TEST_QUEST_ID = '62605a66cc9d4c0013b79342',
  COTININE_LABCORP = '5ed511872c4e3700195bf2e1',
  COTININE_LABCORP_2 = '5ed511872c4e3700195bf2e4',
  COTININE_QUEST = '5ed511872c4e3700195bf2e0',
  BCA_ONSITE = '5f1b740ec35fb90012d476bc',
  EHS_WEIGHT = '5ee242b0d710a80019b67adc',
  EHS_HEIGHT = '5ee23ecad710a80019b67ad3'
}

export const COLOCARE = [
  UserTestId(UserTestIdList.COLOCARE_TEST_LABCORP_ID), // Added support for both LabCorp and Quest ColoCare tests
  UserTestId(UserTestIdList.COLOCARE_TEST_QUEST_ID)
];

export const COTININE_TESTS = [
  UserTestId(UserTestIdList.COTININE_LABCORP),
  UserTestId(UserTestIdList.COTININE_LABCORP_2),
  UserTestId(UserTestIdList.COTININE_QUEST)
];

export enum RESULT_CODE_COMMENTS {
  LABCORP_ALLERGY_CLASS_DESCRIPTION = '602444',
  LABCORP_LH_CODE = '004290',
  LABCORP_LDL_COMMENT_CODE = '011923',
  LABCORP_WRITTEN_AUTHORIZATION = '977900',
  QUEST_BUN_CREAT_RATION = '25000300'
}

export const IGNORE_QUESTS_TESTS = [
  '55075300', // FREE T4 INDEX (T7)
  '30001110', // ABSOLUTE BAND NEUTROPHILS
  '30001310', // ABSOLUTE METAMYELOCYTES
  '30001530', // ABSOLUTE PROMYELOCYTES
  '30003500', // ABSOLUTE BLASTS
  '30003610', // ABSOLUTE NUCLEATED RBC
  '30001100', // BAND NEUTROPHILS
  '30001300', // METAMYELOCYTES
  '30001500', // MYELOCYTES
  '30001520', // PROMYELOCYTES
  '30001510', // ABSOLUTE MYELOCYTES
  '30002000', // REACTIVE LYMPHOCYTES
  '30003400', // BLASTS
  '30003600', // NUCLEATED RBC
  '30004200' // COMMENT(S)
];
