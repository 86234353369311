import { Injectable } from '@angular/core';
import { IdentityPlatformUtil, User } from '@common';
import {
  Auth,
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  UserCredential,
  inMemoryPersistence,
  PhoneAuthProvider,
  PhoneInfoOptions,
  ApplicationVerifier,
  multiFactor,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
  MultiFactorError,
  MultiFactorResolver,
  PhoneMultiFactorAssertion,
  MultiFactorAssertion,
  MultiFactorInfo,
  TotpMultiFactorAssertion,
  TotpMultiFactorGenerator,
  MultiFactorSession,
  TotpSecret
} from 'firebase/auth';
import { from, lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { FirebaseAuthError } from '../app-store/auth/auth.facade';
import { clientLogger } from '../client-logger';
import { AuthHttpService } from '@http';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {
  /**
   * Latest credentials.
   */
  private latestCredentials$ = new ReplaySubject<UserCredential | undefined>(0);

  private latestMFAResolver$ = new ReplaySubject<
    MultiFactorResolver | undefined
  >(0);

  /**
   * The auth instance
   */
  public auth: Auth;
  constructor(private authHttpService: AuthHttpService) {
    this.auth = getAuth();
    this.auth.setPersistence(inMemoryPersistence);
    this.latestCredentials$.next(undefined);
  }

  public signInWithEmailAndPassword(params: {
    username: string;
    password: string;
  }) {
    const { username, password } = params;

    return from(
      signInWithEmailAndPassword(
        this.auth,
        IdentityPlatformUtil.getEmailFromUser({ user: { username } }),
        password
      )
    );
  }

  /**
   * Re-authenticates the given user with firebase.
   * Should be called before calling updatePassword
   */
  public reauthenticate(params: {
    /**
     * The current user signed in
     */
    user: Pick<User, 'username'>;
    /**
     * The current user's password, used to re-authenticate
     */
    password: string;
  }) {
    const { user, password } = params;

    return from(
      signInWithEmailAndPassword(
        this.auth,
        IdentityPlatformUtil.getEmailFromUser({ user }),
        password
      )
    ).pipe(
      mergeMap((authCredentials) =>
        from(
          reauthenticateWithCredential(
            authCredentials.user,
            EmailAuthProvider.credential(
              IdentityPlatformUtil.getEmailFromUser({ user }),
              password
            )
          )
        )
      ),
      tap((val) => this.latestCredentials$.next(val)),
      catchError((error: FirebaseAuthError) => {
        if (error.code == 'auth/multi-factor-auth-required') {
          this.setMultiFactorResolver(error as MultiFactorError);
        }
        throw error;
      })
    );
  }

  /**
   * Update the current password for the current user.
   * **note** this should be called recently AFTER
   * re-authentication.
   *
   * Clears the current credential value
   */
  public updatePassword(params: { newPassword: string }) {
    const { newPassword } = params;

    return this.latestCredentials$.pipe(
      take(1),
      filter((_) => !!_),
      tap(() => this.latestCredentials$.next(undefined)),
      mergeMap((authCredentials) => {
        return from(authCredentials.user.getIdToken());
      }),
      mergeMap((idToken) => {
        //Using the REST API directly to prevent the result to include refresh/id tokens
        return this.authHttpService.updatePassword({
          apiKey: this.auth.app.options.apiKey,
          password: newPassword,
          idToken
        });
      })
    );
  }

  /**
   * Signs out of firebase auth, this should be done as soon as possible after
   * being authenticated with the backend.
   */
  public signOut() {
    return from(this.auth.signOut());
  }

  public getMultiFactorSession() {
    if (!this.auth.currentUser) {
      throw Error('Error retrieving current user');
    }

    return lastValueFrom(
      this.latestCredentials$.pipe(
        mergeMap((userCredentials) => {
          return from(multiFactor(userCredentials.user).getSession());
        }),
        take(1)
      )
    );
  }

  /**
   * Send the uses phoneNumber and SMS code for MFA,
   * this function works for both Setting up MFA, and Verifying MFA
   */

  public sendSMS(params: {
    phoneInfoOptions: PhoneInfoOptions;
    captcha: ApplicationVerifier;
  }): Promise<string> {
    const { phoneInfoOptions, captcha } = params;
    const phoneAuthProvider = new PhoneAuthProvider(this.auth);

    return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, captcha);
  }

  /**
   * Verify the given TOTP code for MFA. Used for both enrolling and verifying already
   * enrolled members. If enrolling, the secret is required. If verifying, the enrollmentId
   * is required.
   */
  public async verifyTOTPCode(params: {
    enrolling?: boolean;
    secret?: TotpSecret;
    enrollmentId?: string;
    verificationCode: string;
  }): Promise<TotpMultiFactorAssertion> {
    const { enrolling, secret, enrollmentId, verificationCode } = params;

    if (enrolling) {
      if (!secret) {
        return;
      }

      return TotpMultiFactorGenerator.assertionForEnrollment(
        secret,
        verificationCode
      );
    }

    if (!enrollmentId) {
      return;
    }

    return TotpMultiFactorGenerator.assertionForSignIn(
      enrollmentId,
      verificationCode
    );
  }

  public async verifySMSCode(params: {
    verificationId: string;
    verificationCode: string;
  }): Promise<PhoneMultiFactorAssertion> {
    const { verificationId, verificationCode } = params;
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    return multiFactorAssertion;
  }

  /**
   * Returns a totp for enrolling in MFA. Can be displayed directly to user to enter
   * into their authenticator app OR to generate a QR code.
   */
  public async generateTOTPSecret(multifactorSession: MultiFactorSession) {
    return await TotpMultiFactorGenerator.generateSecret(multifactorSession);
  }

  public async enrollMFA(
    multiFactorAssertion: MultiFactorAssertion,
    name: string
  ) {
    //Firebase User
    const user = await lastValueFrom(
      this.latestCredentials$.pipe(
        map((userCredentials) => userCredentials.user),
        take(1)
      )
    );

    return multiFactor(user).enroll(multiFactorAssertion, name);
  }

  public async setMultiFactorResolver(err: MultiFactorError) {
    try {
      const resolver = getMultiFactorResolver(this.auth, err);

      this.latestMFAResolver$.next(resolver);
    } catch (error) {
      clientLogger.error(error);
    }
  }

  public getLatestResolver() {
    return this.latestMFAResolver$.asObservable();
  }

  public setLatestCredentials(userCredentials: UserCredential) {
    this.latestCredentials$.next(userCredentials);
  }

  public getUserEnrolledFactors(): Observable<MultiFactorInfo[]> {
    return this.latestCredentials$.pipe(
      map((userCredential) => multiFactor(userCredential.user)?.enrolledFactors)
    );
  }

  public async unEnrollMFA(factorId: string) {
    if (!factorId) {
      return;
    }

    const multiFactorUser = await lastValueFrom(
      this.latestCredentials$.pipe(
        take(1),
        map((userCredential) => multiFactor(userCredential.user))
      )
    );

    try {
      await multiFactorUser.unenroll(factorId);

      return;
    } catch (error) {
      clientLogger.error(error);

      throw error;
    }
  }

  public hasLatestCredentials$() {
    return this.latestCredentials$.pipe(map((value) => !!value));
  }

  public getLatestToken$() {
    return this.latestCredentials$.pipe(
      take(1),
      switchMap((value) => from(value.user.getIdToken()))
    );
  }

  public getLatestUserName$() {
    return this.latestCredentials$.pipe(
      take(1),
      map((value) => value.user?.displayName)
    );
  }
}
