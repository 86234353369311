import {
  getId,
  OffsiteUserRegistration,
  OnsiteUserRegistration,
  UserRegistration,
  UserRegistrationId,
  UserRegistrationStatus
} from '@common';
import {
  entitySelectionReducerFactory,
  EntitySelectionState,
  selectId
} from '@ehs-ngrx/common';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { userRegistrationNgrxActions } from './user-registration-ngrx.actions';

export const USER_REGISTRATION_STORE_KEY = 'userRegistrationNgrx';

export interface ParentUserRegistrationNgrxState {
  [USER_REGISTRATION_STORE_KEY]: UserRegistrationNgrxState;
}

export interface UserRegistrationNgrxState
  extends EntityState<UserRegistration>,
    EntitySelectionState<UserRegistrationId> {
  loading?: boolean;
  hasNext?: boolean;
  pageNumber?: number;
}

const adapter = createEntityAdapter<UserRegistration>({ selectId });

export const userRegistrationReducer = createReducer<
  UserRegistrationNgrxState,
  Action
>(
  adapter.getInitialState({ pageNumber: 1 }),

  on(userRegistrationNgrxActions.clearAll, (state) => adapter.removeAll(state)),

  on(userRegistrationNgrxActions.set, (state, { entity }) =>
    adapter.upsertOne(entity, state)
  ),

  on(userRegistrationNgrxActions.get.req, (state) => ({
    ...state,
    loading: true
  })),

  on(
    userRegistrationNgrxActions.get.success,
    (state, { payload: { entity } }) =>
      adapter.upsertOne(entity, { ...state, loading: false })
  ),

  on(userRegistrationNgrxActions.get.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.updateOptionalTests.req, (state) => ({
    ...state,
    loading: true
  })),

  on(
    userRegistrationNgrxActions.updateOptionalTests.success,
    (state, { payload }) =>
      adapter.upsertOne(payload, { ...state, loading: false })
  ),

  on(userRegistrationNgrxActions.updateOptionalTests.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.updateAdditionalTests.req, (state) => ({
    ...state,
    loading: true
  })),

  on(
    userRegistrationNgrxActions.updateAdditionalTests.success,
    (state, { payload }) =>
      adapter.upsertOne(payload, { ...state, loading: false })
  ),

  on(userRegistrationNgrxActions.updateAdditionalTests.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.updateServiceTypes.req, (state) => ({
    ...state,
    loading: true
  })),

  on(
    userRegistrationNgrxActions.updateServiceTypes.success,
    (state, { payload }) =>
      adapter.upsertOne(payload, { ...state, loading: false })
  ),

  on(userRegistrationNgrxActions.updateServiceTypes.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.updateNotes.req, (state) => ({
    ...state,
    loading: true
  })),

  on(userRegistrationNgrxActions.updateNotes.success, (state, { payload }) =>
    adapter.upsertOne(payload.entity, { ...state, loading: false })
  ),

  on(userRegistrationNgrxActions.updateNotes.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.listAsAdmin.req, (state) => ({
    ...state,
    loading: true
  })),

  on(
    userRegistrationNgrxActions.listAsAdmin.success,
    (state, { payload: { entities } }) =>
      adapter.upsertMany(entities, { ...state, loading: false })
  ),

  on(userRegistrationNgrxActions.listAsAdmin.failed, (state) => ({
    ...state,
    loading: true
  })),

  on(userRegistrationNgrxActions.listByFilter.req, (state) => ({
    ...state,
    loading: true
  })),

  on(
    userRegistrationNgrxActions.listByFilter.success,
    (state, { payload: { healthProviderUploads, hasNext } }) =>
      adapter.upsertMany(healthProviderUploads, {
        ...state,
        hasNext,
        loading: false
      })
  ),

  on(userRegistrationNgrxActions.listByFilter.failed, (state) => ({
    ...state,
    loading: true
  })),

  on(userRegistrationNgrxActions.listByCompany.req, (state) => ({
    ...state,
    loading: true
  })),

  on(
    userRegistrationNgrxActions.listByCompany.success,
    (state, { payload: { userRegistrations } }) =>
      adapter.upsertMany(userRegistrations, {
        ...state,

        loading: false
      })
  ),

  on(userRegistrationNgrxActions.listByCompany.failed, (state) => ({
    ...state,
    loading: true
  })),

  on(userRegistrationNgrxActions.loadMore.req, (state) => ({
    ...state,
    loading: true,
    pageNumber: !state.pageNumber ? 1 : state.pageNumber + 1
  })),
  on(
    userRegistrationNgrxActions.loadMore.success,
    (state, { payload: { healthProviderUploads, hasNext } }) =>
      adapter.upsertMany(healthProviderUploads, {
        ...state,
        hasNext,
        loading: false
      })
  ),
  on(userRegistrationNgrxActions.loadMore.failed, (state) => ({
    ...state,
    loading: false
  })),
  on(userRegistrationNgrxActions.setPageNumber, (state, { pageNumber }) => ({
    ...state,
    pageNumber
  })),
  on(userRegistrationNgrxActions.clear, (state) => adapter.removeAll(state)),

  on(userRegistrationNgrxActions.getUserReq.req, (state) => ({
    ...state,
    loading: true
  })),

  on(
    userRegistrationNgrxActions.getUserReq.success,
    (state, { payload: { userRegistration, userRequisition } }) =>
      adapter.updateOne(
        {
          id: getId(userRegistration),
          changes: {
            userRequisition
          } as Partial<OnsiteUserRegistration | OffsiteUserRegistration>
        },
        { ...state, loading: false }
      )
  ),

  on(userRegistrationNgrxActions.getUserReq.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.getUserReqs.req, (state) => ({
    ...state,
    loading: true
  })),
  on(
    userRegistrationNgrxActions.getUserReqs.success,
    (state, { payload: { userRequisitions } }) =>
      adapter.updateMany(
        userRequisitions.map((userRequisition) => ({
          id: getId(userRequisition.userRegistration),
          changes: {
            userRequisition
          } as Partial<OnsiteUserRegistration>
        })),
        { ...state, loading: false }
      )
  ),
  on(userRegistrationNgrxActions.getUserReqs.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.remove.req, (state) => ({
    ...state,
    loading: true
  })),

  on(userRegistrationNgrxActions.remove.success, (state, { payload: { id } }) =>
    adapter.removeOne(getId(id), { ...state, loading: false })
  ),

  on(userRegistrationNgrxActions.remove.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(
    userRegistrationNgrxActions.removeVaccinationAndRegistration.req,
    (state) => ({
      ...state,
      loading: true
    })
  ),

  on(
    userRegistrationNgrxActions.removeVaccinationAndRegistration.success,
    (state, { payload: { id } }) =>
      adapter.removeOne(getId(id), { ...state, loading: false })
  ),

  on(
    userRegistrationNgrxActions.removeVaccinationAndRegistration.failed,
    (state) => ({
      ...state,
      loading: false
    })
  ),

  on(userRegistrationNgrxActions.removeRegistrationOnly.req, (state) => ({
    ...state,
    loading: true
  })),

  on(
    userRegistrationNgrxActions.removeRegistrationOnly.success,
    (state, { payload: { id } }) =>
      adapter.removeOne(getId(id), { ...state, loading: false })
  ),

  on(userRegistrationNgrxActions.removeRegistrationOnly.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(
    userRegistrationNgrxActions.sendToLab.success,
    (state, { payload: { entities } }) =>
      adapter.upsertMany(entities, { ...state, loading: false })
  ),

  on(userRegistrationNgrxActions.sendToLab.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.sendToLab.req, (state) => ({
    ...state,
    loading: true
  })),

  on(userRegistrationNgrxActions.sendToLab.canceled, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.exportToCsv.success, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.exportToCsv.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.exportToCsv.req, (state) => ({
    ...state,
    loading: true
  })),

  on(
    userRegistrationNgrxActions.markNoShow.success,
    (state, { payload: { entities } }) =>
      adapter.upsertMany(
        entities.map((entity) => ({
          ...entity,
          status: UserRegistrationStatus.NO_SHOW
        })),
        { ...state, loading: false }
      )
  ),

  on(userRegistrationNgrxActions.markNoShow.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.markNoShow.req, (state) => ({
    ...state,
    loading: true
  })),

  on(userRegistrationNgrxActions.markNoShow.canceled, (state) => ({
    ...state,
    loading: false
  })),

  on(
    userRegistrationNgrxActions.markResulted.success,
    (state, { payload: { entities } }) =>
      adapter.upsertMany(
        entities.map((entity) => ({
          ...entity,
          isResulted: true
        })),
        { ...state, loading: false }
      )
  ),
  on(userRegistrationNgrxActions.markResulted.failed, (state) => ({
    ...state,
    loading: false
  })),
  on(userRegistrationNgrxActions.markResulted.req, (state) => ({
    ...state,
    loading: true
  })),
  on(userRegistrationNgrxActions.markResulted.canceled, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.removeVaccination.success, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.removeVaccination.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.removeVaccination.req, (state) => ({
    ...state,
    loading: true
  })),

  on(userRegistrationNgrxActions.removeVaccination.canceled, (state) => ({
    ...state,
    loading: false
  })),

  on(
    userRegistrationNgrxActions.markCompleted.success,
    (state, { payload: { entities } }) =>
      adapter.upsertMany(
        entities.map((entity) => ({
          ...entity,
          status: UserRegistrationStatus.COMPLETED
        })),
        { ...state, loading: false }
      )
  ),

  on(userRegistrationNgrxActions.markCompleted.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.markCompleted.req, (state) => ({
    ...state,
    loading: true
  })),

  on(userRegistrationNgrxActions.markCompleted.canceled, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.release.req, (state) => ({
    ...state,
    loading: true
  })),

  on(
    userRegistrationNgrxActions.release.success,
    (state, { payload: { entities } }) =>
      adapter.upsertMany(entities, { ...state, loading: false })
  ),

  on(userRegistrationNgrxActions.release.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationNgrxActions.changeOnsiteEventDate.req, (state) => ({
    ...state,
    loading: true
  })),

  on(
    userRegistrationNgrxActions.changeOnsiteEventDate.success,
    (state, { payload: { entity } }) =>
      adapter.upsertOne(entity, { ...state, loading: false })
  ),

  on(userRegistrationNgrxActions.changeOnsiteEventDate.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(
    userRegistrationNgrxActions.update.success,
    (state, { payload: { entity } }) =>
      adapter.upsertOne(entity, {
        ...state,
        loading: false
      })
  ),

  ...entitySelectionReducerFactory<
    UserRegistrationNgrxState,
    UserRegistrationId,
    'USER_REGISTRATION_NGRX'
  >({
    entitySelectionActions: userRegistrationNgrxActions
  })
);
