import { ServiceType } from '../../models/event-service/service-type';
import { BaseUserRegistration } from '../../models/user-registration/base-user-registration';
import { UserResult } from '../../models/user-result/user-result';
import { UserResultTestResult } from '../../models/user-result/user-result-test-result';
import { LabResultCode } from '../../models/user-test/user-test';
import { UserTestUtil } from './user-test-util';

/** Whether the user uses tobacco or not */
export enum IsTobaccoUser {
  YES = 'y',
  NO = 'n',
  PENDING = 'pending'
}

export class UserTestCotinineUtil {
  /**
   * Cotinine = Tobacco Usage, it can be captured as a Lab Result, Data entered by admins or self reported by the user
   * We first check if the lab results include a string cotinine value of positive / negative.
   * Otherwise we check if it includes a number cotinine value where if number < 8 then it is positive
   * Otherwise we check if the admin added the useTobacco on the userResult in data entry
   * Otherwise we check if the user checked the usesTobacco on user-registration
   * Otherwise we leave the value null
   */
  public static isTobaccoUser({
    userResult,
    userRegistration
  }: {
    userResult: Partial<UserResult>;
    userRegistration: BaseUserRegistration;
  }): IsTobaccoUser | null {
    // Flatten the userResult.tests to check against the different tests
    const userTestResultMap = UserTestUtil.getFlatUserResultTests({
      userResult: userResult as Pick<UserResult, 'tests'>
    });

    if (
      userRegistration.serviceType !== ServiceType.PROVIDER_HEALTH &&
      !userRegistration.isResulted
    ) {
      return undefined;
    }

    /**
     * First check if Qualitative lab test has a value
     * Quest Cotinine (86002583) | Labcorp Cotinine (070323) ==> Positive / Negative
     */
    const cotinineStringValue = this.getTestValue({
      resultCodes: ['070323', '86002583'],
      userTestResultMap
    });

    if (typeof cotinineStringValue === 'string') {
      if (
        ['qns', 'tnp', 'dnr', '-'].includes(
          (cotinineStringValue || '').toLowerCase()
        )
      ) {
        return IsTobaccoUser.PENDING;
      }

      return (cotinineStringValue || '').toLowerCase() === 'positive'
        ? IsTobaccoUser.YES
        : IsTobaccoUser.NO;
    }

    /**
     * Second check if Quantitative lab test has a value >= 20
     * Labcorp Cotinine (071260) ==> Number
     * Check issue #3540 for more details
     */
    const labcorpCotinineValue = this.getTestValue({
      resultCodes: ['071260'],
      userTestResultMap
    });

    if (typeof labcorpCotinineValue === 'string') {
      if (UserTestUtil.isLowerEstimationValue(labcorpCotinineValue)) {
        // **Note** we assume all `<` values to be the lower estimation value, to follow
        // along with the behavior of all other parts of the app.
        return IsTobaccoUser.NO;
      }

      if (UserTestUtil.isUpperEstimationValue(labcorpCotinineValue)) {
        // **Note** we assume all `<` values to be the lower estimation value, to follow
        // along with the behavior of all other parts of the app.
        return IsTobaccoUser.YES;
      }

      return null;
    }

    if (typeof labcorpCotinineValue === 'number') {
      return labcorpCotinineValue >= 20 ? IsTobaccoUser.YES : IsTobaccoUser.NO;
    }

    /**
     * Third check if Quantitative lab test has a value >= 8
     * Quest Cotinine (86008011)
     */
    const questCotinineValue = this.getTestValue({
      resultCodes: ['86008011'],
      userTestResultMap
    });

    if (typeof questCotinineValue === 'string') {
      if (UserTestUtil.isLowerEstimationValue(questCotinineValue)) {
        // **Note** we assume all `<` values to be the lower estimation value, to follow
        // along with the behavior of all other parts of the app.
        return IsTobaccoUser.NO;
      }

      if (UserTestUtil.isUpperEstimationValue(questCotinineValue)) {
        // **Note** we assume all `<` values to be the lower estimation value, to follow
        // along with the behavior of all other parts of the app.
        return IsTobaccoUser.YES;
      }

      return null;
    }

    if (typeof questCotinineValue === 'number') {
      return questCotinineValue >= 16 ? IsTobaccoUser.YES : IsTobaccoUser.NO;
    }

    // Check if Admin Data entered the tobacco usage
    if (userResult.usesTobacco !== undefined) {
      return userResult.usesTobacco ? IsTobaccoUser.YES : IsTobaccoUser.NO;
    }

    // Check if user self reported the tobacco use on registration
    if (
      userRegistration.isResulted &&
      userRegistration?.usesTobacco !== undefined
    ) {
      return userRegistration.usesTobacco
        ? IsTobaccoUser.YES
        : IsTobaccoUser.NO;
    }

    // Otherwise we leave it blank
    return null;
  }

  /**
   * Get value from the User Result Test Map
   */
  private static getTestValue = ({
    resultCodes,
    userTestResultMap
  }: {
    resultCodes: Array<LabResultCode | string>;
    userTestResultMap: Record<LabResultCode, UserResultTestResult>;
  }): string | number | undefined => {
    const labCode = resultCodes.find(
      (resultCode) => !!userTestResultMap[LabResultCode(resultCode)]
    );

    if (!labCode) {
      return undefined;
    }

    return userTestResultMap[LabResultCode(labCode)]?.value;
  };
}
