import { TestCategories } from '../../constants/test-categories';
import { COVIDVaccines } from '../../constants/vaccines';
import { EventService } from '../../models/event-service/event-service';
import { OffsiteService } from '../../models/event-service/offsite-service';
import { OnsiteService } from '../../models/event-service/onsite-service';
import { ServiceLabType } from '../../models/event-service/service-lab-type';
import { ServiceType } from '../../models/event-service/service-type';
import { PayType } from '../../models/pay-type';
import { Genders } from '../../models/user';
import { BaseSiteUserRegistration } from '../../models/user-registration/base-site-user-registration';
import { OnsiteUserRegistration } from '../../models/user-registration/onsite-user-registration';
import { UserResult } from '../../models/user-result/user-result';
import { UserResultTest } from '../../models/user-result/user-result-test';
import {
  UserResultTestResult,
  UserResultTestResultRangeStatus,
  outOfRangeHighStatusMap,
  outOfRangeLowStatusMap
} from '../../models/user-result/user-result-test-result';
import {
  LabResultCode,
  LoincCode,
  PrimaryOrderCode,
  UserTest,
  UserTestId
} from '../../models/user-test/user-test';
import { UserTestGraphType } from '../../models/user-test/user-test-graph-type';
import { UserTestRange } from '../../models/user-test/user-test-range';
import { UserTestRangeColors } from '../../models/user-test/user-test-range-colors';
import { isUserTestStatusActive } from '../../models/user-test/user-test-status';
import { EventServiceUtil } from '../event-service-util';
import { getId } from '../get-id';
import { toMap } from '../to-map';
import { OptionalTestsCategoryMap } from './optional-tests-category-map';
import { AdditionalTestsCategoryMap } from './additional-tests-category-map';
import { OutOfRange } from './out-of-range';
import { CategoryTestData, TestCategoryMap } from './test-category-map';
import { UserTestUtilRanges } from './user-test-util-ranges';
import {
  CustomValueComparison,
  HealthRisk,
  HealthRiskThresholds,
  HealthRiskTrigger,
  UserTestRisk
} from '../../models/user-test/user-test-risk';
import { UserTestType } from '../../models/user-test/user-test-type';
import { EHS_CHOLESTEROL_RESULT_CODE } from '../../constants/chol-ratio';
import {
  IGNORE_QUESTS_TESTS,
  RESULT_CODE_COMMENTS
} from '../../constants/user-test-id-list';
import {
  EHS_BMI_RESULT_CODE,
  LABCORP_BMI_RESULT_CODE
} from '../../constants/bmi';
import {
  LABCORP_FOLATE_RESULT_CODE,
  QUEST_FOLATE_RESULT_CODE
} from '../../constants/folate';
import {
  LABCORP_EGFR_RESULT_CODE,
  QUEST_EGFR_RESULT_CODE
} from '../../constants/egfr';
import { LABCORP_LIPOPROTEIN_RESULT_CODE } from '../../constants/lipoprotein';

/**
 * A display range is used as part of the graph display for user-results
 *
 * This should ONLY be used to display the graph
 */
export interface DisplayRange {
  /**
   * The color of the bar in the display
   */
  background: UserTestRangeColors;
  /**
   * The number that is on the "left"
   * side of the bar, this isn't shown
   */
  startValue: number;
  /**
   * The value of the bar, which is placed to the RIGHT.
   * The "first" bar from the left wont have its value shown.
   */
  value: number;
  /**
   * The risk definition property we are to show for this display-range.
   * @deprecated don't use this, as it will be removed
   */
  riskDefinition: keyof Pick<
    UserTest,
    'highRiskDefinition' | 'inRangeDefinition' | 'lowRiskDefinition'
  >;
  /**
   * @deprecated don't use this, as it will be removed
   */
  status?: UserResultTestResultRangeStatus;
}
/**
 * Static utility class to help handle user-tests and user-result logic
 */
export class UserTestUtil {
  /**
   * Utility function that returns only filters only user-tests.
   * This should only be applied to end-user rendering.
   */
  public static getActive(userTest: UserTest[]): UserTest[] {
    return (userTest || []).filter(isUserTestStatusActive);
  }

  /**
   * Returns if the given user result has
   * a vaccination type of JNJ
   */
  public static isSingleShotUserResult(params: {
    userResult: UserResult;
  }): boolean {
    const { userResult } = params;
    // **Note** might make sense to move this, but do it later if needed.
    const singleShotVaccines = [COVIDVaccines.JANSSEN_JOHNSON_AND_JOHNSON];

    const vaccineType = userResult?.vaccine?.vaccineType;

    if (!vaccineType) {
      // If there is no vaccine-type on the user-result, then just return false.
      return false;
    }

    return singleShotVaccines.includes(vaccineType);
  }

  /**
   * Returns the user-result-test-result from the given
   * result-code and full user-result.
   */
  public static getResultFromResultCode(params: {
    userResult: UserResult;
    resultCode: string;
  }):
    | {
        userResult: UserResult;
        userResultTestResult: UserResultTestResult;
      }
    | undefined {
    const { userResult, resultCode } = params;

    if (!userResult || !userResult.tests || !userResult.tests.length) {
      return undefined;
    }

    for (const test of userResult.tests) {
      for (const result of test.results) {
        if (result.resultCode === resultCode) {
          return {
            userResult,
            userResultTestResult: result
          };
        }
      }
    }

    return undefined;
  }

  /**
   * Returns result test from given result with a matching LOINC code
   */
  public static getResultFromLoincCode(params: {
    userResult: UserResult;
    loincCode: string;
  }):
    | {
        userResult: UserResult;
        userResultTestResult: UserResultTestResult;
      }
    | undefined {
    const { userResult, loincCode } = params;

    if (!userResult || !userResult.tests || !userResult.tests.length) {
      return undefined;
    }

    for (const test of userResult.tests) {
      for (const result of test.results) {
        if (result.loincId === loincCode) {
          return {
            userResult,
            userResultTestResult: result
          };
        }
      }
    }

    return undefined;
  }

  /**
   * Returns the cost of the vaccination, if there is one
   * for the user to pay for. Insurance pay types are not supported, as
   * these are never defined as any amount. We will only collect
   * their insurance information and EHS will manage the actual payment.
   *
   * We return null if payment can't be calculated yet,
   * but return 0 if it has been and its actually 0
   */
  public static getVaccinationToPayFor(params: {
    /**
     * The pay-type to match. Will work with the groupType
     * selected via the user-registration to determine
     * if the user is to be prompted to pay.
     */
    payType: PayType.SELF_PAY | PayType.EMPLOYER;
    /**
     * The event-service to get the group-type information for
     */
    eventService: EventService;
    /**
     * The user's registration, used to get the group-type.
     *
     * Vaccinations are only supported for onsite.
     */
    userRegistration: Pick<OnsiteUserRegistration, 'groupType'>;
  }): number | null {
    const { payType, eventService, userRegistration } = params;
    const onsiteService = EventServiceUtil.getSelectedService({
      eventService,
      serviceType: ServiceType.OFFSITE
    }) as OnsiteService;

    if (!onsiteService) {
      return null;
    }

    // **Note** this is done to prevent overflow
    const groupType = (onsiteService.groupTypes || []).find(
      (_, index) => index === userRegistration.groupType
    );

    if (!groupType) {
      // No groupType available
      return null;
    }

    if (payType === PayType.EMPLOYER) {
      return 0;
    }
    const payVaccination = groupType.payVaccinations?.includes(payType);

    if (!payVaccination) {
      // The user doesn't have to pay
      return 0;
    }
    const vaccinationPrice = groupType.vaccinationPrice;

    if (!vaccinationPrice) {
      // The vaccination cost is 0
      return 0;
    }

    return vaccinationPrice;
  }

  /**
   * Returns the individual test cost depending on
   * the event-service test costs.
   */
  public static getTestCost(params: {
    userTest: UserTest;
    eventService: EventService;
  }): number | null {
    const { userTest, eventService } = params;

    if (!userTest || !eventService) {
      return null;
    }

    const relevantCustomPricing = (eventService.customPricing || []).find(
      (customPricing) => getId(customPricing.test) === getId(userTest)
    );

    if (!relevantCustomPricing) {
      return userTest.cost;
    }

    return relevantCustomPricing.cost;
  }

  /**
   * Returns the user-tests the user needs to pay for depending on
   * their group-type settings, set within the event-service.
   *
   * Insurance pay types are not supported, as
   * these are never defined as any amount. We will only collect
   * their insurance information and EHS will manage the actual payment.
   *
   * **note** may change how pay-type is applied, for now it
   * considers all pay types to be the same.
   *
   * **note** does not include payment for vaccinations!
   * See #1898 for notes
   *
   * @returns the array of user-tests to charge for.
   */
  public static getUserTestsToPayFor(params: {
    /**
     * The payment type to match. Will work with the
     * groupType selected via the user-registration to determine
     * if the user is to be "prompted" to pay
     * for the given payment types.
     */
    payType: PayType.EMPLOYER | PayType.SELF_PAY;
    /**
     * The event-service to get the group-type information for
     */
    eventService: EventService;
    /**
     * All user-tests available or mentioned within the event-service,
     * without duplicates
     */
    userTests: Array<UserTest>;
    /**
     * The user's registration, used to get the group-type
     */
    userRegistration: Pick<
      BaseSiteUserRegistration,
      'groupType' | 'optionalTests' | 'serviceType'
    >;
  }): Array<UserTest> {
    const { payType, eventService, userTests, userRegistration } = params;

    if (!userRegistration) {
      return [];
    }

    const service = EventServiceUtil.getSelectedService({
      eventService,
      serviceType: userRegistration.serviceType
    }) as OffsiteService | OnsiteService;

    if (!service) {
      return [];
    }

    // **Note** this is done to prevent overflow
    const groupType = (service.groupTypes || []).find(
      (_, index) => index === userRegistration.groupType
    );

    if (!groupType) {
      // No groupType available
      return [];
    }

    if (payType === PayType.EMPLOYER) {
      return [];
    }
    const payOptionalTests = groupType.payOptionalTests?.includes(payType);
    const payPackage = groupType.payPackage?.includes(payType);
    const userTestsMap: Record<string, UserTest> = toMap({
      entities: userTests
    });
    const userTestsToPayFor: UserTest[] = [];

    if (!payOptionalTests && !payPackage) {
      // There are no user-tests to pay for, as neither are self-pay
      return [];
    }

    const removeDuplicates = <T>(elements: Array<T>) =>
      Array.from(new Set([...elements]).values());

    if (payOptionalTests) {
      // If optional tests are to be paid for, add them
      userTestsToPayFor.push(
        ...removeDuplicates(
          service.optionalTests.map(getId).filter((optionalTest) =>
            // We only include this, if it was registered
            userRegistration.optionalTests?.includes(getId(optionalTest))
          )
        ).map((userTest) => userTestsMap[getId(userTest)])
      );
    }

    if (payPackage) {
      // If package is self-paid for, then add the package and additional tests
      userTestsToPayFor.push(
        ...removeDuplicates(
          [
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            service.panel!,
            ...service.additionalServices,
            ...service.additionalTests
          ].map(getId)
        )
          // Mainly for panel existing or not
          .filter((_) => !!_)
          .map((userTest) => userTestsMap[getId(userTest)])
      );
    }

    return userTestsToPayFor;
  }

  /**
   * Returns a human readable lab name. By default only supports lab-corp
   * and quest labs. Can support others with an optional flag
   */
  public static getLabName(
    labType: ServiceLabType,
    options?: {
      /**
       * If we are to include names for helena-labs and EHS
       */
      includeAlts?: boolean;
    }
  ): string {
    const includeAlts = !!options?.includeAlts;

    switch (labType) {
      case ServiceLabType.LAB_CORP:
        return 'LabCorp';
      case ServiceLabType.QUEST:
        return 'Quest';
      // These shouldn't be possible
      case ServiceLabType.HELENA_LABS:
        return includeAlts ? 'Helena Labs' : '';
      case ServiceLabType.EHS:
        return includeAlts ? 'EHS' : '';
      default:
        return '';
    }
  }

  /**
   * Map of user-tests where the key is the user-test code
   *
   * **note** using the `PrimaryOrderCode` as a lookup isn't always advised due
   * to `PrimaryOrderCode` not always being a unique identifier.
   */
  public static getUserTestsMap(params: {
    userTests: UserTest[];
  }): Record<PrimaryOrderCode, UserTest> {
    const { userTests } = params;

    return (userTests || []).reduce(
      (acc, entity) => {
        const code = UserTestUtil.getCode(entity);

        if (!code) {
          return acc;
        }

        return { ...acc, ['' + code]: entity };
      },
      {} as Record<PrimaryOrderCode, UserTest>
    );
  }

  /**
   * Map of user-tests where the key is the user-test's lab-result-code.
   *
   * This is the same as `getUserTestMap`, but has a different ts-s
   */
  public static getUserTestsLabMap(params: {
    userTests: UserTest[];
  }): Record<LabResultCode, UserTest> {
    const { userTests } = params;

    return (userTests || []).reduce(
      (acc, entity) => {
        const code = entity?.labResultCode;

        if (!code) {
          return acc;
        }

        return { ...acc, [code]: entity };
      },
      {} as Record<LabResultCode, UserTest>
    );
  }

  /**
   * Return map of user tests with loinc codes as key
   */
  public static getUserTestsByLoincMap(params: {
    userTests: UserTest[];
  }): Record<string, UserTest> {
    const { userTests } = params;

    return (userTests || []).reduce(
      (acc, entity) => {
        const code = entity?.loincCode;

        if (!code) {
          return acc;
        }

        return { ...acc, [code]: entity };
      },
      {} as Record<string, UserTest>
    );
  }

  /**
   * Returns if the given value is an "estimation value", which
   * is a value with `<` or `>` in the front.
   */
  public static isEstimationValue(value: string | unknown): boolean {
    return (
      this.isLowerEstimationValue(value) || this.isUpperEstimationValue(value)
    );
  }

  /**
   * Returns if the given value is the upper estimation value, or starts with `>`
   * **note** this might not ever be given and is at least considered to be "good", so this may be removed in the future
   */
  public static isUpperEstimationValue(value: string | unknown): boolean {
    return !!value && typeof value === 'string' && value.trim().startsWith('>');
  }

  /**
   * Returns if the given value is the lower estimation value, or ends starts with `<`
   */
  public static isLowerEstimationValue(value: string | unknown): boolean {
    return !!value && typeof value === 'string' && value.trim().startsWith('<');
  }

  /**
   * Returns the "raw" integer value for the estimation value, if the value isn't
   * an estimation value we return null;
   */
  public static getRawEstimationValue(value: string | number): number | null {
    if (!this.isEstimationValue(value)) {
      return null;
    }

    return Number((value as string).replace('<', '').replace('>', ''));
  }

  /**
   * Returns the number value of a test. This will return the number value of a string or just the number as is.
   * If a value of <1 is passed, we return 1
   * If number 10.5 is passed we return 10.5
   * If a string "NotReported" is passed we return null
   * If an object is passed we return null
   */
  public static getNumberValue(value: string | number): number | null {
    if (typeof value === 'number') {
      return value;
    }

    if (typeof value === 'string') {
      const rawValue = this.getRawEstimationValue(value);

      if (rawValue === undefined || rawValue === null || isNaN(rawValue)) {
        return null;
      }

      return rawValue;
    }

    return null;
  }

  /**
   * Returns the user result test results from the user result
   * that are related to the given health risk
   */
  public static getHealthRiskResults(params: {
    userResultTestResults: UserResultTestResult[];
    userTestLabMap: Record<string, UserTest>;
    healthRisk: HealthRisk;
    missingTests?: CategoryTestData[];
  }) {
    const { userResultTestResults, userTestLabMap, healthRisk, missingTests } =
      params;

    if (!userResultTestResults?.length || !userTestLabMap || !healthRisk) {
      return [];
    }

    const loincCodes = [] as LoincCode[];

    // Filter to only out of range and high risk metabolic syndrome tests
    const filtered = userResultTestResults.filter((result) => {
      const userTest = userTestLabMap[getId(result.resultCode)];

      const isRelatedTest = !!this.getHealthRiskInfo({
        userTest,
        healthRisk
      });

      if (isRelatedTest) {
        loincCodes.push(userTest.loincCode);
      }

      return isRelatedTest;
    });

    // Loop through missing biometric tests and add to arr
    // If they are related to the health risk
    if (missingTests?.length) {
      const filteredMissingTests = missingTests.reduce((arr, missingTest) => {
        const userTest = missingTest.userTest;
        const userResultTestResult = missingTest.userResultTestResult;

        if (
          !userTest?.name ||
          !userTest.labResultCode ||
          !userResultTestResult?.value
        ) {
          return arr;
        }

        // If result code already exists from lab result, dont add from missing tests
        if (loincCodes.includes(userTest.loincCode)) {
          return arr;
        }

        const isHealthRiskFactor = !!this.getHealthRiskInfo({
          userTest,
          healthRisk
        });

        if (isHealthRiskFactor) {
          const newUserResultTestResult = {
            value: userResultTestResult.value,
            resultCode: userTest.labResultCode,
            resultName: userTest.name
          } as Partial<UserResultTestResult>;

          loincCodes.push(userTest.loincCode);
          arr.push(newUserResultTestResult as UserResultTestResult);
        }

        return arr;
      }, [] as UserResultTestResult[]);

      filtered.push(...filteredMissingTests);
    }

    return filtered;
  }

  /**
   * Return the UserTestRisk for the given user-test and health-risk
   * or undefined if not found
   */
  public static getHealthRiskInfo(params: {
    userTest: UserTest;
    healthRisk: HealthRisk;
  }): UserTestRisk | undefined {
    const { userTest, healthRisk } = params;

    if (!userTest?.risks?.length || !healthRisk) {
      return undefined;
    }

    return userTest.risks.find((risk) => risk.healthRisk === healthRisk);
  }

  /**
   * Returns true if the user result value is greater than or less than the
   * custom value set in the user test risk (based on the custom value comparison
   * setting). Otherwise, returns false.
   */
  public static isTriggeredByCustomValue(params: {
    userTestRisk: UserTestRisk;
    userResultTestResult: UserResultTestResult;
  }): boolean {
    const { userTestRisk, userResultTestResult } = params;

    // If no risk trigger or no result value, return false
    if (!userTestRisk?.riskTrigger?.length || !userResultTestResult?.value) {
      return false;
    }

    // If custom value is not a risk trigger, return false
    // If custom value is a risk trigger, but custom value is not a number, return false
    if (
      !userTestRisk.riskTrigger.includes(HealthRiskTrigger.CUSTOM_VALUE) ||
      typeof userTestRisk.customValue !== 'number'
    ) {
      return false;
    }

    switch (userTestRisk.customValueComparison) {
      case CustomValueComparison.GREATER_THAN:
        return (
          this.getNumberValue(userResultTestResult.value) >
          userTestRisk.customValue
        );
      case CustomValueComparison.LESS_THAN:
        return (
          this.getNumberValue(userResultTestResult.value) <
          userTestRisk.customValue
        );
      case CustomValueComparison.GREATER_THAN_OR_EQUAL:
        return (
          this.getNumberValue(userResultTestResult.value) >=
          userTestRisk.customValue
        );
      case CustomValueComparison.LESS_THAN_OR_EQUAL:
        return (
          this.getNumberValue(userResultTestResult.value) <=
          userTestRisk.customValue
        );
      default:
        return false;
    }
  }

  /**
   * Returns true if the user result test results and user's age and gender
   * trigger the given health risk. Otherwise, returns false.
   */
  public static isHealthRiskTriggered(params: {
    healthRisk: HealthRisk;
    userResultTestResults: UserResultTestResult[];
    userTestLabMap: Record<string, UserTest>;
    age: number;
    gender: Genders;
  }) {
    const { healthRisk, userResultTestResults, userTestLabMap, age, gender } =
      params;

    if (
      !healthRisk ||
      !userResultTestResults?.length ||
      !userTestLabMap ||
      !age ||
      !gender
    ) {
      return false;
    }

    // Get array of only out of range tests
    const riskScore = userResultTestResults.reduce((score, test) => {
      const userTest = userTestLabMap[test.resultCode];

      // Get the health risk info for the user test for the given health risk
      const healthRiskInfo = this.getHealthRiskInfo({
        userTest,
        healthRisk
      });

      if (!healthRiskInfo) {
        return score;
      }

      // Get the range for the test
      const testRange = this.getUserTestRange({
        userTest: userTest,
        age: age,
        gender: gender
      });

      // Get the section of the range the result falls into
      const range = this.getRangeStatus({
        userResultTestResult: test,
        userTestRange: testRange
      });

      if (range === UserResultTestResultRangeStatus.NORMAL) {
        return score;
      }

      /**
       *  If result range falls into one of the out of range statuses
       *  on the high end and a has a trigger for out of range high,
       *  add to array
       */
      if (
        healthRiskInfo.riskTrigger.includes(
          HealthRiskTrigger.OUT_OF_RANGE_HIGH
        ) &&
        outOfRangeHighStatusMap[range]
      ) {
        score = score + (healthRiskInfo.riskScore || 0);

        return score;
      }

      /**
       * If result range falls into one of the out of range statuses
       * on the low end and has a trigger for out of range low,
       * add to array
       */
      if (
        healthRiskInfo.riskTrigger.includes(
          HealthRiskTrigger.OUT_OF_RANGE_LOW
        ) &&
        outOfRangeLowStatusMap[range]
      ) {
        score = score + (healthRiskInfo.riskScore || 0);

        return score;
      }

      if (
        this.isTriggeredByCustomValue({
          userTestRisk: healthRiskInfo,
          userResultTestResult: test
        })
      ) {
        score = score + (healthRiskInfo.riskScore || 0);
      }

      return score;
    }, 0);

    // Return true if the risk score is greater than the threshold
    return riskScore >= HealthRiskThresholds[healthRisk];
  }

  /**
   * Returns the user-test-range color based on the user-result
   * and the user-test.
   *
   * **NOTE** this will return undefined if the user-test graph-type is not
   * POSITIVE, or NEGATIVE.
   */
  public static getBooleanDisplayColor(params: {
    userResultTestResult: UserResultTestResult;
    userTest: UserTest;
  }): UserTestRangeColors | string | undefined {
    const { userResultTestResult, userTest } = params;

    if (!userResultTestResult || !userTest) {
      return undefined;
    }

    if (
      ![UserTestGraphType.POSITIVE, UserTestGraphType.NEGATIVE].includes(
        userTest.graphType
      )
    ) {
      return undefined;
    }
    const value = '' + userResultTestResult.value || '';

    if (value.toLowerCase() === 'positive') {
      if (userTest.graphType === UserTestGraphType.POSITIVE) {
        return UserTestRangeColors.GREEN;
      }

      return UserTestRangeColors.RED;
    }

    if (value.toLowerCase() === 'negative') {
      if (userTest.graphType === UserTestGraphType.NEGATIVE) {
        return UserTestRangeColors.GREEN;
      }

      return UserTestRangeColors.RED;
    }

    return undefined;
  }

  /**
   * Returns the user-test-range-color based on the user-test-range.
   * Replaces the `getDisplayRangeColor` method
   */
  public static getDisplayColor(params: {
    /**
     * The user-result-test-result, used to return the display-range-color
     */
    userResultTestResult: UserResultTestResult;
    /**
     * The test-range from the user-test, available
     * from getUserTestRange.
     */
    userTestRange: UserTestRange;
    /**
     * The user-test, we used the graph-type to determine the color for an
     * estimated value. This was changed as of #3706, as estimated values could
     * affect "full-graphs" and not just "partial-graph" values.
     */
    userTest: Partial<UserTest>;
    /**
     * Color to provide for not-established values OR not-applicable values
     * By default we return undefined for these values
     */
    notEstablishOverride?: string;
  }): UserTestRangeColors | string | undefined {
    const {
      userResultTestResult,
      userTestRange,
      notEstablishOverride,
      userTest
    } = params;

    if (!userResultTestResult || !userTestRange) {
      return notEstablishOverride || undefined;
    }

    // Utility function to check if for a special "estimation value" case.
    // depending on how often this shows up, we may need to make
    // changes to this AGAIN to make it more generic.
    // **note** taken from get-range, might consolidate?
    const isMergedHighRiskRange = () =>
      userTestRange.highNormal === userTestRange.highRisk;

    if (typeof userResultTestResult.value === 'string') {
      const isLowerEstimation = this.isLowerEstimationValue(
        userResultTestResult.value
      );
      const isUpperEstimation = this.isUpperEstimationValue(
        userResultTestResult.value
      );

      if (isLowerEstimation) {
        if (userTest.graphType === UserTestGraphType.LINE_GRAPH) {
          // If the graph is a full line-graph, then the furthest left element
          // is actually red, and thus this is a "bad" value
          return UserTestRangeColors.RED;
        }

        /**
         * If its a partial line graph, furthest left is green.
         * Some times, lab will return an lower estimation with a
         * higher number.
         */
        if (userTest.graphType === UserTestGraphType.PARTIAL_GRAPH) {
          const numVal = this.getNumberValue(userResultTestResult.value);

          // If fingerstick cholesterol estimation || labcorp LH estimation, return yellow
          if (
            (userTest.labResultCode === EHS_CHOLESTEROL_RESULT_CODE &&
              userTest.testType === UserTestType.FINGER_STICK) ||
            userTest.labResultCode === RESULT_CODE_COMMENTS.LABCORP_LH_CODE
          ) {
            return UserTestRangeColors.YELLOW;
          }

          return numVal > userTestRange.highRisk
            ? UserTestRangeColors.RED
            : UserTestRangeColors.GREEN;
        }

        // If the value is the lower estimation, then we can assume its "good"
        return UserTestRangeColors.GREEN;
      }

      if (isUpperEstimation) {
        if (isMergedHighRiskRange()) {
          return UserTestRangeColors.YELLOW;
        }

        return UserTestRangeColors.RED;
      }

      if (userResultTestResult.value.toLowerCase() === 'not applicable') {
        // If the range is not applicable, we return the override or undefined
        return notEstablishOverride || undefined;
      }
    }

    if (userResultTestResult.range === 'NotEstab.') {
      // If the range is not established, we return the override or undefined
      return notEstablishOverride || undefined;
    }
    // Otherwise calculate the range value
    const range = this.getRange(params);

    switch (range) {
      case 'inRange':
        return UserTestRangeColors.GREEN;
      case 'outOfRange':
        return UserTestRangeColors.YELLOW;
      case 'highRisk':
      default:
        return UserTestRangeColors.RED;
    }
  }

  /**
   * Returns if the given result is "critical", which will be shown
   * to admins via the critical-call feature.
   *
   * This returns true for
   * - Values over qcHigh values and if qcHigh values are > 0
   * - Values below panicLow values and if panicLow values are > 0
   */
  public static isCritical(params: {
    userResultTestResult: UserResultTestResult;
    range: UserTestRange;
  }): boolean {
    const { userResultTestResult, range } = params;

    if (!userResultTestResult || !range) {
      return false;
    }
    const value = userResultTestResult.value as number;

    // If the `qcHigh` value does not exist or is 0 we ignore the critical
    if (range.qcHigh && range.qcHigh > 0 && value >= range.qcHigh) {
      // The value is high enough to be a "critical-call"
      return true;
    }

    if (range.panicLow && range.panicLow > 0 && value <= range.panicLow) {
      // The value is low enough to be a "critical-call"
      return true;
    }

    return false;
  }

  /**
   * Returns the display ranges by themselves. Used to generate the bar graph for the user
   */
  public static getDisplayRanges(params: {
    /**
     * The userResultTestResult we are to use.
     * The value should be a number.
     */
    userResultTestResult: UserResultTestResult;
    /**
     * The list of ranges that apply to the current user.
     * Should be available from `.filter` and isInRage
     */
    range: UserTestRange;
  }): DisplayRange[] | undefined {
    const { userResultTestResult, range } = params;

    if (!userResultTestResult || !range) {
      return undefined;
    }

    const { highRisk: max } = range;

    if (userResultTestResult.resultCode === LABCORP_EGFR_RESULT_CODE) {
      return [
        {
          background: UserTestRangeColors.RED,
          startValue: range.lowRisk,
          value: range.lowNormal,
          riskDefinition: 'lowRiskDefinition',
          status: UserResultTestResultRangeStatus.BELOW_NORMAL
        },
        {
          background: UserTestRangeColors.GREEN,
          startValue: range.lowNormal,
          value: max,
          riskDefinition: 'inRangeDefinition',
          status: UserResultTestResultRangeStatus.NORMAL
        }
      ];
    }

    if (userResultTestResult.resultCode === LABCORP_LIPOPROTEIN_RESULT_CODE) {
      return [
        {
          background: UserTestRangeColors.GREEN,
          startValue: range.lowRisk,
          value: range.highNormal,
          riskDefinition: 'inRangeDefinition',
          status: UserResultTestResultRangeStatus.NORMAL
        },
        {
          background: UserTestRangeColors.RED,
          startValue: range.highNormal,
          value: max,
          riskDefinition: 'lowRiskDefinition',
          status: UserResultTestResultRangeStatus.ABOVE_NORMAL
        }
      ];
    }

    if (
      userResultTestResult.resultCode === LABCORP_FOLATE_RESULT_CODE ||
      userResultTestResult.resultCode === QUEST_FOLATE_RESULT_CODE
    ) {
      return [
        {
          background: UserTestRangeColors.RED,
          startValue: range.panicLow,
          value: range.lowRisk,
          riskDefinition: 'lowRiskDefinition',
          status: UserResultTestResultRangeStatus.BELOW_NORMAL_PANIC
        },
        {
          background: UserTestRangeColors.YELLOW,
          startValue: range.lowRisk,
          value: range.lowNormal,
          riskDefinition: 'lowRiskDefinition',
          status: UserResultTestResultRangeStatus.BELOW_NORMAL
        },
        {
          background: UserTestRangeColors.GREEN,
          startValue: range.highNormal,
          value: max,
          riskDefinition: 'lowRiskDefinition',
          status: UserResultTestResultRangeStatus.ABOVE_NORMAL
        }
      ];
    }

    if (
      userResultTestResult.resultCode.includes(EHS_BMI_RESULT_CODE) ||
      userResultTestResult.resultCode.includes(LABCORP_BMI_RESULT_CODE)
    ) {
      return [
        {
          background: UserTestRangeColors.RED,
          startValue: range.lowRisk,
          value: range.lowNormal,
          riskDefinition: 'lowRiskDefinition',
          status: UserResultTestResultRangeStatus.BELOW_NORMAL
        },
        {
          background: UserTestRangeColors.GREEN,
          startValue: range.lowNormal,
          value: range.highNormal,
          riskDefinition: 'inRangeDefinition',
          status: UserResultTestResultRangeStatus.NORMAL
        },
        {
          background: UserTestRangeColors.YELLOW,
          startValue: range.highNormal,
          value: range.highRisk,
          riskDefinition: 'highRiskDefinition',
          status: UserResultTestResultRangeStatus.ABOVE_NORMAL
        },
        {
          background: UserTestRangeColors.RED,
          startValue: range.highRisk,
          value: max,
          riskDefinition: 'highRiskDefinition',
          status: UserResultTestResultRangeStatus.ABOVE_NORMAL_PANIC
        }
      ];
    }

    return [
      // Anything less than panic low isn't graphed
      ...((range.lowRisk >= 0 && range.lowRisk !== range.lowNormal
        ? [
            {
              background: UserTestRangeColors.RED,
              startValue: range.panicLow,
              value: range.lowRisk,
              riskDefinition: 'lowRiskDefinition',
              status: UserResultTestResultRangeStatus.BELOW_NORMAL_PANIC
            },
            {
              background: userResultTestResult.resultCode.includes(
                QUEST_EGFR_RESULT_CODE
              )
                ? UserTestRangeColors.RED
                : UserTestRangeColors.YELLOW,
              startValue: range.lowRisk,
              value: range.lowNormal,
              riskDefinition: 'lowRiskDefinition',
              status: UserResultTestResultRangeStatus.BELOW_NORMAL
            }
          ]
        : []) as Array<DisplayRange>),
      {
        background: UserTestRangeColors.GREEN,
        startValue: range.lowNormal,
        value: range.highNormal,
        riskDefinition: 'inRangeDefinition',
        status: UserResultTestResultRangeStatus.NORMAL
      },
      {
        background: UserTestRangeColors.YELLOW,
        startValue: range.highNormal,
        value: range.highRisk,
        riskDefinition: 'highRiskDefinition',
        status: UserResultTestResultRangeStatus.ABOVE_NORMAL
      },
      {
        background: UserTestRangeColors.RED,
        startValue: range.highRisk,
        value: max,
        riskDefinition: 'highRiskDefinition',
        status: UserResultTestResultRangeStatus.ABOVE_NORMAL_PANIC
      }
    ];
  }

  /**
   * Returns the correct explained text for a given result+user-test-range
   * for the given user-test.
   *
   * **note** this is one of the few methods that handles positive/negative internally.
   * Most of the time this is handled externally.
   */
  public static getExplained(params: {
    /**
     * The user result to get the explained text for
     */
    userResultTestResult: UserResultTestResult;
    /**
     * The user-test the test-result goes to
     */
    userTest: UserTest;
    /**
     * The range the user falls under for the user-test
     */
    userTestRange: UserTestRange;
  }): string {
    const { userResultTestResult, userTest, userTestRange } = params;

    if (!userResultTestResult || !userTestRange || !userTest) {
      return '';
    }
    const { value } = userResultTestResult;

    if (typeof value === 'string') {
      if (userTest.graphType === UserTestGraphType.POSITIVE) {
        if (this.isPositive({ testResult: userResultTestResult })) {
          return userTest.inRangeDefinition;
        }

        return userTest.highRiskDefinition;
      }

      if (userTest.graphType === UserTestGraphType.NEGATIVE) {
        if (this.isNegative({ testResult: userResultTestResult })) {
          return userTest.inRangeDefinition;
        }

        return userTest.highRiskDefinition;
      }

      const isLowerEstimation = this.isLowerEstimationValue(
        userResultTestResult.value
      );
      const isUpperEstimation = this.isUpperEstimationValue(
        userResultTestResult.value
      );

      if (isLowerEstimation) {
        // This might of been a bug?
        if (userTest.graphType === UserTestGraphType.PARTIAL_GRAPH) {
          const numValue = this.getNumberValue(userResultTestResult.value);

          return numValue > userTestRange.highRisk
            ? userTest.highRiskDefinition
            : userTest.lowRiskDefinition;
        }

        return userTest.lowRiskDefinition;
      }

      if (isUpperEstimation) {
        // This might of been a bug?
        return userTest.highRiskDefinition;
      }
    }

    if (
      userTestRange.lowRisk > this.getNumberValue(value) ||
      (userTestRange.lowRisk <= this.getNumberValue(value) &&
        this.getNumberValue(value) < userTestRange.lowNormal)
    ) {
      return userTest.lowRiskDefinition;
    }

    if (
      userTestRange.lowNormal <= this.getNumberValue(value) &&
      this.getNumberValue(value) <= userTestRange.highNormal
    ) {
      return userTest.inRangeDefinition;
    }

    if (
      userTestRange.highNormal < this.getNumberValue(value) &&
      this.getNumberValue(value) < userTestRange.highRisk
    ) {
      return userTest.highRiskDefinition;
    }

    // Under a special case, if all values are the same, display all of them as-such
    const allAreSame = [
      userTestRange.lowRisk,
      userTestRange.lowNormal,
      userTestRange.highNormal,
      userTestRange.highRisk
    ].every((val, _, arr) => val === arr[0]);

    if (allAreSame && userTestRange.lowRisk === value) {
      return userTest.inRangeDefinition;
    }

    // Otherwise the definition is above EVERYTHING
    return userTest.highRiskDefinition;
  }

  /**
   * Returns the list of ranges+user-test+result that are out of range.
   * Useful for creating critical-results.
   *
   */
  public static getOutOfRange(params: {
    /**
     * The user result to
     */
    userResult: UserResult;
    /**
     * The age of the user, used to calculate the ranges available
     */
    age: number | null;
    /**
     * The user's gender, used to calculate the ranges available
     */
    gender: Genders;
    /**
     * User-tests to ignore, these are usually "EHS" tests,
     * such as blood pressure
     */
    ignoreCodes?: Array<LabResultCode>;
    userTests: Record<LabResultCode, UserTest> | UserTest[];
  }): OutOfRange[] {
    const { userTests, userResult, age, gender, ignoreCodes } = params;

    if (!userTests || !userResult || !userResult.tests) {
      return [];
    }

    const userTestsLabMap = Array.isArray(userTests)
      ? toMap({
          entities: userTests,
          key: 'labResultCode'
        })
      : userTests;

    return userResult.tests.reduce((acc, userResultTest) => {
      // **Note** using similar logic as getTestCategoryMap
      (userResultTest.results || [])
        .filter((userResultTestResult) => {
          if (!ignoreCodes || !ignoreCodes.length) {
            // If there are no codes to ignore, then continue
            return true;
          }

          const ignoreUserTest = ignoreCodes.includes(
            userResultTestResult.resultCode
          );

          // Only include those that are NOT ignored!
          return !ignoreUserTest;
        })
        .forEach((userResultTestResult) => {
          if (!userResultTestResult) {
            return;
          }
          const userTest = userTestsLabMap[userResultTestResult.resultCode];

          if (!userTest) {
            return;
          }

          const range = this.getUserTestRange({
            userTest,
            age,
            gender
          });

          if (!!range && this.isCritical({ userResultTestResult, range })) {
            acc.push({
              userResultTestResult,
              userTest,
              userTestRange: range
            });
          }
        });

      return acc;
    }, [] as Array<OutOfRange>);
  }

  /**
   * Returns if the given user-test-range is within
   * the user's range information
   */
  public static isInRange(params: {
    range: UserTestRange;
    age: number;
    gender: Genders;
  }): boolean {
    const { range, age, gender } = params;
    const matchesGender = range.gender === 'both' || range.gender === gender;
    const matchesAge = range.ageHigh >= age && range.ageLow <= age;
    // If both the high and low matches, match anything
    const matchAllAges = range.ageHigh === range.ageLow;

    return matchesGender && (matchesAge || matchAllAges);
  }

  /**
   * Returns the list of test codes to search for from the user-result
   */
  public static getTestCodes(params: { userResult: UserResult }): string[] {
    const { userResult } = params;

    if (!userResult || !userResult.tests) {
      return [];
    }

    return Array.from(
      userResult.tests
        .reduce((acc, test) => {
          // The parent test id
          acc.add(test.id);
          (test.results || []).forEach((result) => acc.add(result.resultCode));

          return acc;
        }, new Set<string>())
        .values()
    );
  }

  /**
   * Returns the list of labResult codes to search for in
   * user-tests
   */
  public static getLabResultCodes(params: {
    userResult: UserResult;
  }): string[] {
    const { userResult } = params;

    if (!userResult || !userResult.tests) {
      return [];
    }

    return Array.from(
      userResult.tests.reduce(
        (set, test) =>
          (test.results || []).reduce(
            (_, result) => set.add(result.resultCode),
            set
          ),
        new Set<string>()
      )
    );
  }

  /**
   * Returns the name of the user-test.
   * Returns the technical name first and foremost
   */
  public static getName(userTest: UserTest): string {
    if (!userTest) {
      return '';
    }

    return userTest.name || userTest.technicalName || '';
  }

  /**
   * Returns the code we are to use for the user-test.
   *
   * **note** this shouldn't be used as often due to `PrimaryOrderCode`
   * not being unique.
   */
  public static getCode(userTest: UserTest): PrimaryOrderCode {
    if (!userTest) {
      return PrimaryOrderCode('');
    }

    return PrimaryOrderCode(
      '' + userTest.primaryOrderCode || '' + userTest.orderCode || ''
    );
  }

  /**
   * Returns a list of test categories from the user-result and
   * map of user-tests to their codes
   */
  public static getTestCategories(params: {
    userTestsMap: Record<string, UserTest>;
    userResult: UserResult;
  }): TestCategories[] {
    const { userTestsMap, userResult } = params;

    if (!userTestsMap || !userResult || !userResult.tests) {
      return [];
    }

    return Array.from(
      userResult.tests
        .reduce((acc, test) => {
          if (!test.id) {
            return acc;
          }
          const userTest = userTestsMap['' + test.id];

          if (!userTest) {
            return acc;
          }

          return acc.add(userTest.category);
        }, new Set<TestCategories>())
        .values()
    );
  }

  /**
   * Returns the first matching test range for the given user-result-test-result
   * This user-test-range should be used later with the user-result-test-result
   * for all display/UI calculation information
   */
  public static getUserTestRange(params: {
    // UserResultTestResult: Pick<UserResultTestResult, 'value'>;
    userTest: UserTest;
    age: number;
    gender: Genders;
  }): UserTestRange | undefined {
    const { userTest } = params;

    if (!userTest || !userTest.ranges) {
      return undefined;
    }

    return userTest.ranges.find((range) =>
      this.isInRange({ ...params, range })
    );
  }

  /**
   * Returns the "range numbers", of the higher and lower values for the given range.
   * Following format: "high"-"low".
   *
   * These are primarily displayed in critical results
   */
  public static getRangeNumbers(params: {
    userTestRange: UserTestRange;
    userResultTestResult: Pick<UserResultTestResult, 'value'>;
  }): string {
    const { userTestRange, userResultTestResult } = params;

    if (!userTestRange || !userResultTestResult) {
      return '';
    }
    // NOTE we are just assuming this is a number.
    const value = userResultTestResult.value as number;

    if (userTestRange.lowRisk > value) {
      return `???-${userTestRange.lowRisk}`;
    }

    if (userTestRange.lowRisk <= value && value < userTestRange.lowNormal) {
      return `${userTestRange.lowRisk}-${userTestRange.lowNormal}`;
    }

    if (userTestRange.lowNormal <= value && value <= userTestRange.highNormal) {
      return `${userTestRange.lowNormal}-${userTestRange.highNormal}`;
    }

    if (userTestRange.highNormal < value && value < userTestRange.highRisk) {
      return `${userTestRange.highNormal}-${userTestRange.highRisk}`;
    }

    // Under a special case, if all values are the same, display all of them as-such
    // **note** this is duplicated in getRange
    const allAreSame = [
      userTestRange.lowRisk,
      userTestRange.lowNormal,
      userTestRange.highNormal,
      userTestRange.highRisk
    ].every((val, _, arr) => val === arr[0]);

    if (allAreSame && userTestRange.lowRisk === value) {
      return `${userTestRange.lowRisk}-${userTestRange.lowRisk}`;
    }

    return `${userTestRange.highRisk}-???`;
  }

  /**
   * Returns the range-numbers that are "normal", similar to getRangeNumbers, but
   * is used exclusively as a fallback to the userResultTestResult.range values
   * return directly from the lab.
   */
  public static getNormalRangeNumbers(params: {
    userTestRange: UserTestRange;
  }): string {
    const { userTestRange } = params;

    if (!userTestRange) {
      return '';
    }

    if (
      userTestRange.lowNormal === undefined &&
      userTestRange.highNormal === undefined
    ) {
      return '';
    }

    // **Note** might be a use-case for new JS syntax?
    const lowNormal =
      userTestRange.lowNormal === undefined ? '???' : userTestRange.lowNormal;
    const highNormal =
      userTestRange.highNormal === undefined ? '???' : userTestRange.highNormal;

    return `${lowNormal}-${highNormal}`;
  }

  /**
   * Returns a map where the key is the user-test code
   * and the value is the user-test and userResultTestResult.
   *
   * @deprecated don't use this moving forward, as `primaryOrderCode`'s aren't always
   * unique, and thus are unreliable lookup keys. see `getFlatUserTestLabMap`, which
   * relies on labResultCode, which *is* unique.
   */
  public static getFlatUserTestMap(params: {
    /**
     * The map of user tests to their labResultCode
     */
    userTestsLabMap: Record<LabResultCode, UserTest>;
    userResult: UserResult;
  }): Record<
    PrimaryOrderCode,
    {
      userTest: UserTest;
      userResultTestResult: UserResultTestResult;
    }
  > {
    const { userTestsLabMap, userResult } = params;

    if (!userTestsLabMap || !userResult || !userResult.tests) {
      return {};
    }

    return userResult.tests.reduce(
      (acc, userResultTest) => {
        (userResultTest.results || []).forEach((result) => {
          if (!result.resultCode) {
            return;
          }
          const userTest = userTestsLabMap[result.resultCode];

          if (!userTest) {
            return;
          }

          acc[this.getCode(userTest)] = {
            userTest,
            userResultTestResult: result
          };
        });

        return acc;
      },
      {} as Record<
        PrimaryOrderCode,
        {
          userTest: UserTest;
          userResultTestResult: UserResultTestResult;
        }
      >
    );
  }

  /**
   * Returns a map where the key is the user's labResultCode
   * and the value is the user-test and userResultTestResult.
   *
   * **note** this is only slightly different than `getFlatUserTestMap`,
   * which was duplicated to get around the fact, apparently *primaryResultCode*'s
   * are not unique, and thus aren't reliable as a lookup key!
   */
  public static getFlatUserTestLabMap(params: {
    /**
     * The map of user tests to their labResultCode
     */
    userTestsLabMap: Record<LabResultCode, UserTest>;
    userResult: UserResult;
  }): Record<
    LabResultCode,
    {
      panel: UserResultTest;
      userTest: UserTest;
      userResultTestResult: UserResultTestResult;
    }
  > {
    const { userTestsLabMap, userResult } = params;

    if (!userTestsLabMap || !userResult || !userResult.tests) {
      return {};
    }

    return userResult.tests.reduce(
      (acc, userResultTest) => {
        (userResultTest.results || []).forEach((result) => {
          if (!result.resultCode) {
            return;
          }

          const userTest: UserTest | undefined =
            userTestsLabMap[result.resultCode];

          if (!userTest) {
            return;
          }

          acc[userTest.labResultCode] = {
            panel: userResultTest,
            userTest,
            userResultTestResult: result
          };
        });

        return acc;
      },
      {} as Record<
        LabResultCode,
        {
          panel: UserResultTest;
          userTest: UserTest;
          userResultTestResult: UserResultTestResult;
        }
      >
    );
  }

  /**
   * The map of user tests to their loincCode, which should
   * be consistent for tests across labs. Checks against lab result code map
   * to try to find user test if loinc code fails.
   */
  public static getFlatUserTestLoincMap(params: {
    /**
     * Map of user tests by lab result code, checked if loinc fails
     */
    userTestsLabMap: Record<LabResultCode, UserTest>;
    /**
     * Map of user tests by loinc code
     */
    userTestsLoincMap: Record<string, UserTest>;
    userResult: UserResult;
  }): Record<
    string,
    {
      panel: UserResultTest;
      userTest: UserTest;
      userResultTestResult: UserResultTestResult;
    }
  > {
    const { userTestsLabMap, userTestsLoincMap, userResult } = params;

    if (
      !userTestsLoincMap ||
      !userTestsLabMap ||
      !userResult ||
      !userResult.tests
    ) {
      return {};
    }

    return userResult.tests.reduce(
      (acc, userResultTest) => {
        (userResultTest.results || []).forEach((result) => {
          if (!result.loincId && !result.resultCode) {
            return;
          }

          let userTest: UserTest | undefined =
            userTestsLabMap[result.resultCode];

          if (!userTest) {
            // Most loincId which are not populated are not
            // actually undefined, they are empty strings
            if (result.loincId.length > 0) {
              userTest = userTestsLoincMap[result.loincId];

              if (!userTest) {
                return;
              }
            }

            return;
          }

          acc[userTest.loincCode] = {
            panel: userResultTest,
            userTest,
            userResultTestResult: result
          };
        });

        return acc;
      },
      {} as Record<
        string,
        {
          panel: UserResultTest;
          userTest: UserTest;
          userResultTestResult: UserResultTestResult;
        }
      >
    );
  }

  /**
   * Returns a map where the key is the user's labResultCode
   * and the value is the userResultTestResult.
   * This is the same as the function `getFlatUserTestLabMap` above however it doesn't return
   * the full userTest, it just returns the UserResultTestResult. This is used when we don't need
   * the full userTest part of the flat user result test array.
   */
  public static getFlatUserResultTests(params: {
    userResult: Pick<UserResult, 'tests'>;
  }): Record<LabResultCode, UserResultTestResult> {
    const { userResult } = params;

    if (!userResult || !userResult.tests) {
      return {};
    }

    return userResult.tests.reduce(
      (acc, userResultTest) => {
        (userResultTest.results || []).forEach((result) => {
          if (!result?.resultCode) {
            return;
          }
          acc[result.resultCode as LabResultCode] = result;
        });

        return acc;
      },
      {} as Record<LabResultCode, UserResultTestResult>
    );
  }

  /**
   * Returns the list of optional user-tests in a category map,
   * similar to the TestCategoryMap.
   */
  public static getOptionalTestsCategoryMap(params: {
    /**
     * The service to get the list of optional tests from
     */
    service: OnsiteService | OffsiteService;
    /**
     * The entities of user-tests where the key is the _id
     */
    userTests: Record<UserTestId, UserTest> | Array<UserTest>;
    /**
     * Tests to check for to avoid duplicates
     */
    avoidTests?: Array<UserTestId>;
  }): OptionalTestsCategoryMap {
    const { service, avoidTests } = params;

    if (!service || !params.userTests || !service.optionalTests) {
      return {};
    }

    const userTests: Record<UserTestId, UserTest> = Array.isArray(
      params.userTests
    )
      ? toMap({
          entities: params.userTests,
          key: '_id'
        })
      : params.userTests;

    return service.optionalTests.reduce((acc, optionalTest) => {
      const userTestId = UserTestId(getId(optionalTest));

      // If the user-test is in the avoid list, skip it
      if (avoidTests?.length && avoidTests.includes(userTestId)) {
        return acc;
      }

      const userTest = userTests[userTestId];

      if (!userTest) {
        // The user-test isn't found, or doesn't exist
        return acc;
      }

      if (!acc[userTest.category]) {
        acc[userTest.category] = [];
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      acc[userTest.category]!.push(userTest);

      return acc;
    }, {} as OptionalTestsCategoryMap);
  }

  /**
   * Returns the list of additional user-tests in a category map,
   * similar to the TestCategoryMap.
   */
  public static getAdditionalTestsCategoryMap(params: {
    /**
     * The service to get the list of additional tests from
     */
    service: OnsiteService | OffsiteService;
    /**
     * The entities of user-tests where the key is the _id
     */
    userTests: Record<UserTestId, UserTest> | Array<UserTest>;
  }): AdditionalTestsCategoryMap {
    const { service } = params;

    if (!service || !params.userTests || !service.optionalTests) {
      return {};
    }

    const userTests: Record<UserTestId, UserTest> = Array.isArray(
      params.userTests
    )
      ? toMap({
          entities: params.userTests,
          key: '_id'
        })
      : params.userTests;

    return (service.additionalTests || []).reduce((acc, additionalTest) => {
      const userTest = userTests[UserTestId(getId(additionalTest))];

      if (!userTest) {
        // The user-test isn't found, or doesn't exist
        return acc;
      }

      if (!acc[userTest.category]) {
        acc[userTest.category] = [];
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      acc[userTest.category]!.push(userTest);

      return acc;
    }, {} as AdditionalTestsCategoryMap);
  }

  /**
   * Returns a "flat map" of the user-test-test-results, nested
   * within the user-result.
   * This skips over panels and or user-result-test grouping,
   * so you can just get
   */
  public static getTestResults(params: {
    userResult: UserResult;
  }): UserResultTestResult[] {
    const { userResult } = params;

    if (!userResult || !userResult.tests) {
      return [];
    }

    return userResult.tests.reduce((acc, userResultTest) => {
      userResultTest.results.forEach((result) => acc.push(result));

      return acc;
    }, [] as UserResultTestResult[]);
  }

  /**
   * Returns a map of testCategories, where the value is the
   * user-result-test-result and the corresponding user-test
   */
  public static getTestCategoryMap(
    params: {
      /**
       * The user result we are checking against
       */
      userResult: UserResult;
      /**
       * The user's age, used to calculate the ranges available.
       */
      age: number;
      /**
       * The user's gender, used to calculate the ranges available
       */
      gender: Genders;
    } & (
      | {
          /**
           * The map of user tests to their labResultCode
           */
          userTestsLabMap: Record<LabResultCode, UserTest>;
        }
      | {
          /**
           * The map of user tests to their user-test-id
           */
          userTestsIdMap: Record<UserTestId, UserTest>;
        }
    )
  ): TestCategoryMap {
    // Define the optional arg types, so its easier to use
    type UserTestsLabMap = Record<LabResultCode, UserTest>;
    type UserTestsIdMap = Record<UserTestId, UserTest>;
    const { userResult, age, gender } = params;

    if (!userResult || !userResult.tests) {
      return {};
    }

    const userTestsLabMap = (params as { userTestsLabMap?: UserTestsLabMap })
      .userTestsLabMap;
    const userTestsIdMap = (params as { userTestsIdMap?: UserTestsIdMap })
      .userTestsIdMap;

    if (!userTestsLabMap && !userTestsIdMap) {
      return {};
    }

    const reduceTests = (userResult.tests || []).reduce(
      (acc, userResultTest) => {
        return (userResultTest.results || []).reduce((_, result) => {
          if (!result.resultCode) {
            return acc;
          }

          const userTestByLabId = userTestsLabMap
            ? userTestsLabMap[result.resultCode]
            : undefined;
          const userTestByIdMap = userTestsIdMap
            ? userTestsIdMap[UserTestId(getId(result.userTest))]
            : undefined;

          // Use the _id version if provided and available
          const userTest = userTestByIdMap || userTestByLabId;

          if (!userTest) {
            return acc;
          }

          if (!isUserTestStatusActive(userTest)) {
            // If the user-test is not active, do not add it.
            return acc;
          }
          const categoryValues = acc.get(userTest.category);
          const userTestRange = this.getUserTestRange({
            userTest,
            age,
            gender
          });

          return acc.set(
            userTest.category,
            categoryValues
              ? [
                  ...categoryValues,
                  {
                    userResultTestResult: result,
                    userTest,
                    userTestRange
                  }
                ]
              : [
                  {
                    userResultTestResult: result,
                    userTest,
                    userTestRange
                  }
                ]
          );
        }, acc);
      },
      new Map<
        TestCategories,
        Array<{
          userTest: UserTest;
          userResultTestResult: UserResultTestResult;
          userTestRange?: UserTestRange;
        }>
      >()
    );

    return Array.from(reduceTests.keys()).reduce(
      (testCategoryMap, testCategory) => ({
        ...testCategoryMap,
        [testCategory]: reduceTests.get(testCategory)
      }),
      {}
    );
  }

  /**
   * Returns a map of testCategories, where the value is the
   * user-result-test-result and the corresponding user-test
   * using loinc codes as the keys rather than result codes
   */
  public static getTestCategoryLoincMap(
    params: {
      /**
       * The user result we are checking against
       */
      userResult: UserResult;
      /**
       * The user's age, used to calculate the ranges available.
       */
      age: number;
      /**
       * The user's gender, used to calculate the ranges available
       */
      gender: Genders;
    } & (
      | {
          /**
           * The map of user tests to their labResultCode
           */
          userTestsLoincMap: Record<string, UserTest>;
        }
      | {
          /**
           * The map of user tests to their user-test-id
           */
          userTestsIdMap: Record<UserTestId, UserTest>;
        }
    )
  ): TestCategoryMap {
    // Define the optional arg types, so its easier to use
    type UserTestsLoincMap = Record<string, UserTest>;
    type UserTestsIdMap = Record<UserTestId, UserTest>;
    const { userResult, age, gender } = params;

    if (!userResult || !userResult.tests) {
      return {};
    }

    const userTestsLoincMap = (
      params as { userTestsLoincMap?: UserTestsLoincMap }
    ).userTestsLoincMap;
    const userTestsIdMap = (params as { userTestsIdMap?: UserTestsIdMap })
      .userTestsIdMap;

    if (!userTestsLoincMap && !userTestsIdMap) {
      return {};
    }

    const reduceTests = (userResult.tests || []).reduce(
      (acc, userResultTest) => {
        return (userResultTest.results || []).reduce((_, result) => {
          if (!result.loincId) {
            return acc;
          }

          const userTestByLoincId = userTestsLoincMap
            ? userTestsLoincMap[result.loincId]
            : undefined;
          const userTestByIdMap = userTestsIdMap
            ? userTestsIdMap[UserTestId(getId(result.userTest))]
            : undefined;

          // Use the _id version if provided and available
          const userTest = userTestByIdMap || userTestByLoincId;

          if (!userTest) {
            return acc;
          }

          if (!isUserTestStatusActive(userTest)) {
            // If the user-test is not active, do not add it.
            return acc;
          }
          const categoryValues = acc.get(userTest.category);
          const userTestRange = this.getUserTestRange({
            userTest,
            age,
            gender
          });

          return acc.set(
            userTest.category,
            categoryValues
              ? [
                  ...categoryValues,
                  {
                    userResultTestResult: result,
                    userTest,
                    userTestRange
                  }
                ]
              : [
                  {
                    userResultTestResult: result,
                    userTest,
                    userTestRange
                  }
                ]
          );
        }, acc);
      },
      new Map<
        TestCategories,
        Array<{
          userTest: UserTest;
          userResultTestResult: UserResultTestResult;
          userTestRange?: UserTestRange;
        }>
      >()
    );

    return Array.from(reduceTests.keys()).reduce(
      (testCategoryMap, testCategory) => ({
        ...testCategoryMap,
        [testCategory]: reduceTests.get(testCategory)
      }),
      {}
    );
  }

  /**
   * Returns the user-test-range percentage from the user-test-ranges
   * Partially replaces the getDisplayRanges and Percentage
   */
  public static getRangePercentage(params: {
    /**
     * The user-result-test-result we are to use.
     * The value should be a number
     */
    userResultTestResult: UserResultTestResult;
    /**
     * The range applied for the current user,
     * should be available with getUserTestRange
     */
    userTestRange: UserTestRange;
  }): number {
    const { userResultTestResult, userTestRange } = params;

    if (
      !userResultTestResult ||
      !userTestRange ||
      typeof userResultTestResult.value !== 'number'
    ) {
      return 0;
    }
    const value = userResultTestResult.value;
    let percentage =
      (value / (userTestRange.highRisk - userTestRange.panicLow)) * 100;

    if (percentage > 100) {
      // If the calculation is over 100, revert to 100
      percentage = 100;
    }

    return percentage;
  }

  /**
   * Returns the range status, which provides 5 values rather than
   * the "getRange" value, which just returns 3 possible values.
   *
   * Replaces getDisplayRangesAndPercentage, except returns only 1 part
   */
  public static getRangeStatus(params: {
    /**
     * The user-result-test-result we are to use.
     * The value should be a number
     */
    userResultTestResult: Pick<UserResultTestResult, 'value'>;
    /**
     * The range applied for the current user,
     * should be available with getUserTestRange
     */
    userTestRange: UserTestRange;
  }): UserResultTestResultRangeStatus | undefined {
    const { userResultTestResult, userTestRange } = params;

    if (
      !userResultTestResult ||
      !userTestRange ||
      typeof userResultTestResult.value !== 'number'
    ) {
      return undefined;
    }

    if (typeof userResultTestResult.value === 'string') {
      const isLowerEstimation = this.isLowerEstimationValue(
        userResultTestResult.value
      );
      const isUpperEstimation = this.isUpperEstimationValue(
        userResultTestResult.value
      );

      if (isLowerEstimation) {
        // If the value is the lower estimation, then we can assume its "good"
        const numValue = this.getNumberValue(userResultTestResult.value);

        // Probably an error from lab, but in case value in lower estimation is NOT
        // actually a high extreme
        if (userTestRange.highRisk < numValue) {
          return UserResultTestResultRangeStatus.ABOVE_NORMAL_PANIC;
        }

        return UserResultTestResultRangeStatus.NORMAL;
      }

      if (isUpperEstimation) {
        return UserResultTestResultRangeStatus.ABOVE_NORMAL;
      }
    }
    const value = userResultTestResult.value as number;

    if (userTestRange.lowRisk > value) {
      return UserResultTestResultRangeStatus.BELOW_NORMAL_PANIC;
    }

    if (userTestRange.lowRisk <= value && value < userTestRange.lowNormal) {
      return UserResultTestResultRangeStatus.BELOW_NORMAL;
    }

    if (userTestRange.lowNormal <= value && value <= userTestRange.highNormal) {
      return UserResultTestResultRangeStatus.NORMAL;
    }

    if (userTestRange.highNormal < value && value < userTestRange.highRisk) {
      return UserResultTestResultRangeStatus.ABOVE_NORMAL;
    }

    // Under a special case, if all values are the same, consider this "normal"
    const allAreSame = [
      userTestRange.lowRisk,
      userTestRange.lowNormal,
      userTestRange.highNormal,
      userTestRange.highRisk
    ].every((val, _, arr) => val === arr[0]);

    if (allAreSame && userTestRange.lowRisk === value) {
      return UserResultTestResultRangeStatus.NORMAL;
    }

    return UserResultTestResultRangeStatus.ABOVE_NORMAL_PANIC;
  }

  /**
   * Returns what range the userResultTestResult is in
   * according to the given userTestRange.
   */
  public static getRange(params: {
    /**
     * The user-result to use the value from
     */
    userResultTestResult: UserResultTestResult;
    /**
     * The user-test we will use.
     */
    userTestRange: UserTestRange;
  }): 'highRisk' | 'inRange' | 'outOfRange' | undefined {
    const { userResultTestResult, userTestRange } = params;

    if (!userResultTestResult || !userTestRange) {
      return undefined;
    }

    // Utility function to check if for a special "estimation value" case.
    // depending on how often this shows up, we may need to make
    // changes to this AGAIN to make it more generic.
    const isMergedHighRiskRange = () =>
      userTestRange.highNormal === userTestRange.highRisk;

    if (typeof userResultTestResult.value === 'string') {
      const isLowerEstimation = this.isLowerEstimationValue(
        userResultTestResult.value
      );
      const isUpperEstimation = this.isUpperEstimationValue(
        userResultTestResult.value
      );

      if (isLowerEstimation) {
        // If the value is the lower estimation, then we can assume its "good"
        const numValue = this.getNumberValue(userResultTestResult.value);

        // Probably an error from lab, but in case value in lower estimation is NOT in range
        if (userTestRange.highRisk < numValue) {
          return 'highRisk';
        }

        return 'inRange';
      }

      if (isUpperEstimation) {
        if (isMergedHighRiskRange()) {
          // If the upper-range is "merged", then return just "out of range"
          return 'outOfRange';
        }

        return 'highRisk';
      }

      if (userResultTestResult.value.toLowerCase() === 'not applicable') {
        // If the range is not applicable, we return undefined.
        return undefined;
      }
    }

    if (typeof userResultTestResult.value !== 'number') {
      return undefined;
    }

    if (userResultTestResult.range === 'NotEstab.') {
      return undefined;
    }
    const value = userResultTestResult.value as number;

    if (userTestRange.lowRisk > value) {
      return 'highRisk';
    }

    if (userTestRange.lowRisk <= value && value < userTestRange.lowNormal) {
      return 'outOfRange';
    }

    if (userTestRange.lowNormal <= value && value <= userTestRange.highNormal) {
      return 'inRange';
    }

    if (userTestRange.highNormal < value && value < userTestRange.highRisk) {
      return 'outOfRange';
    }

    // Under a special case, if all values are the same, consider this "normal"
    // **note** this is duplicated in getRangeNumbers, and getExplained
    const allAreSame = [
      userTestRange.lowRisk,
      userTestRange.lowNormal,
      userTestRange.highNormal,
      userTestRange.highRisk
    ].every((val, _, arr) => val === arr[0]);

    if (allAreSame && userTestRange.lowRisk === value) {
      return 'inRange';
    }

    if (isMergedHighRiskRange()) {
      // If the highest values are "merged", then return just "out of range"
      return 'outOfRange';
    }

    return 'highRisk';
  }

  /**
   * Returns a map test results to their respective categories if the result
   * is within the given range.
   **/
  public static getTestsFromCategoryMapByRange(params: {
    userTestCategoryMap: TestCategoryMap;
    rangeFilter: 'highRisk' | 'inRange' | 'outOfRange';
  }): Record<TestCategories, UserResultTestResult[]> {
    const { userTestCategoryMap, rangeFilter } = params;

    if (!userTestCategoryMap || !rangeFilter) {
      return {} as Record<TestCategories, UserResultTestResult[]>;
    }

    const outputMap = {} as Record<TestCategories, UserResultTestResult[]>;

    Object.keys(userTestCategoryMap).forEach((category) => {
      const tests = userTestCategoryMap[category] || [];

      outputMap[category] = tests.filter(
        ({ userResultTestResult, userTestRange }) => {
          return (
            this.getRange({ userResultTestResult, userTestRange }) ===
            rangeFilter
          );
        }
      );
    });

    return outputMap;
  }

  /**
   *
   * Returns the list of ranges from the user-test-category map
   * TODO: refactor with alternate version that test a list of category-test-data
   */
  public static getRangesFromCategoryMap(params: {
    userTestCategoryMap: TestCategoryMap;
  }): UserTestUtilRanges {
    const { userTestCategoryMap } = params;

    if (!userTestCategoryMap) {
      return { highRisk: 0, inRange: 0, outOfRange: 0, total: 0 };
    }

    return Object.values(userTestCategoryMap).reduce(
      (acc, tests) => {
        tests.forEach(({ userResultTestResult, userTestRange }) => {
          const range = userTestRange
            ? this.getRange({ userResultTestResult, userTestRange })
            : undefined;

          if (!range) {
            return;
          }
          acc[range]++;
          acc.total++;
        });

        return acc;
      },
      {
        highRisk: 0,
        inRange: 0,
        outOfRange: 0,
        total: 0
      } as UserTestUtilRanges
    );
  }

  /**
   * Return true if the user-result-test-result has a value that means the test is not reported.
   * This can happen if the test was inconclusive or if the patient cancelled the test at the lab.
   * We check first for the valueType to be `ST` or `TX`, otherwise we will show the result.
   * The 2 values as far as we know so far are if value = 'TNP' (Test not reported) or '-' (Basically no value)
   * or 'DNR' or if it is an empty string.
   *
   * DNR = Did Not Report
   * TNP = Test Not Performed
   * ST = ??? Not sure, but returned from Quest
   *
   * **note** this used to check the test-type, but no longer does and just goes 100%
   * off the value now.
   */
  public static isNoResult(params: {
    userResultTestResult: Pick<UserResultTestResult, 'value'>;
    admin?: boolean;
  }): boolean {
    const { userResultTestResult, admin } = params;

    if (!userResultTestResult) {
      return false;
    }

    // Filter out quest tests that we do not want to cause failed tests
    if (
      IGNORE_QUESTS_TESTS.includes(
        (userResultTestResult as UserResultTestResult).resultCode?.toString()
      )
    ) {
      return false;
    }

    /**
     * This is a check for specific BUN/CREATINE ration, only return as no result if user
     * and admin will not see this as a failed result.  This is due to Quest sometimes sending
     * result values and sometimes sending 'SEE NOTE:' even when the user is in the normal range.
     * */
    if (
      (userResultTestResult as UserResultTestResult).resultCode ===
        RESULT_CODE_COMMENTS.QUEST_BUN_CREAT_RATION &&
      !admin
    ) {
      return [
        'TNP',
        '-',
        '',
        'DNR',
        'ST',
        'NOT APPLICABLE',
        'SEE NOTE:'
      ].includes(userResultTestResult.value as string);
    }

    // This is a check for specific allergy test which does not return a value
    if (
      (userResultTestResult as UserResultTestResult).resultCode ===
        RESULT_CODE_COMMENTS.LABCORP_ALLERGY_CLASS_DESCRIPTION ||
      (userResultTestResult as UserResultTestResult).resultCode ===
        RESULT_CODE_COMMENTS.LABCORP_LDL_COMMENT_CODE ||
      (userResultTestResult as UserResultTestResult).resultCode ===
        RESULT_CODE_COMMENTS.LABCORP_WRITTEN_AUTHORIZATION
    ) {
      return false;
    }

    if (userResultTestResult) {
      return [
        'TNP',
        '-',
        '',
        'DNR',
        'ST',
        'NOT APPLICABLE',
        'SEE NOTE:'
      ].includes(userResultTestResult.value as string);
    }

    return false;
  }

  /**
   * Returns if the given user-test is negative
   */
  public static isNegative(params: {
    testResult: UserResultTestResult;
  }): boolean {
    const { testResult } = params;

    if (typeof testResult.value === 'string') {
      return testResult.value.toLowerCase().includes('negative');
    }

    return false;
  }

  /**
   * Returns if the given user-test is positive
   */
  public static isPositive(params: {
    testResult: UserResultTestResult;
  }): boolean {
    const { testResult } = params;

    if (typeof testResult.value === 'string') {
      return testResult.value.toLowerCase().includes('positive');
    }

    return false;
  }

  /**
   * Utility function that returns the test-definition, or short one as a
   * backup in the given language. Defaults to english.
   */
  public static getTestDefinition(params: {
    userTest: UserTest;
    language?: 'en' | 'es';
  }): string {
    const { userTest, language } = params;

    if (language === 'es') {
      return userTest?.testDefinitionEs || userTest?.shortDefinitionEs;
    }

    return userTest?.testDefinition || userTest?.shortDefinition;
  }

  /**
   * Utility function that returns the short test-definition, or long one as a
   * backup in the given language. Defaults to english.
   */
  public static getShortTestDefinition(params: {
    userTest: UserTest;
    language?: 'en' | 'es';
  }): string {
    const { userTest, language } = params;

    if (language === 'es') {
      return userTest?.shortDefinitionEs || userTest?.testDefinitionEs;
    }

    return userTest?.shortDefinition || userTest?.testDefinition;
  }

  /**  This function will return ranges text ex. '100-200'.  This will first check if the result is a
   * Positive/Negative value, next if the lab sends range with the results, and if none of those exist
   * we will use the reference range entered by Admin.  If none of these exist a empty string will be returned ''.
   * */
  public static getRangeText(params: {
    testResult: Pick<UserResultTestResult, 'value' | 'range'>;
    userTestRange: Pick<UserTestRange, 'lowNormal' | 'highNormal'>;
  }): string {
    const { testResult, userTestRange } = params;

    if (!testResult) {
      return '';
    }

    if (
      UserTestUtil.isPositive({
        testResult: testResult as UserResultTestResult
      }) ||
      UserTestUtil.isNegative({
        testResult: testResult as UserResultTestResult
      })
    ) {
      return '';
    }

    if (testResult.range) {
      return testResult.range.toString();
    }

    if (userTestRange) {
      return this.getNormalRangeNumbers({
        userTestRange: userTestRange as UserTestRange
      });
    }

    return '';
  }
}
