import {
  EHS_BP_DIA_RESULT_CODE,
  EHS_BP_SYS_RESULT_CODE
} from '../constants/blood-pressure';
import { EHS_BMI_RESULT_CODE, LABCORP_BMI_RESULT_CODE } from '../constants/bmi';
import { DbDocument } from '../models/db-document';
import { EventService } from '../models/event-service/event-service';
import {
  IncentiveGrouping,
  IncentiveRewardMethod,
  IncentiveTier,
  IncentiveType
} from '../models/event-service/event-service-incentives';
import { BloodPressureRange, BmiRange } from '../models/hra/hra-result';
import {
  ButtonType,
  Incentive,
  IncentiveAdminValidation,
  IncentiveAwardStatus,
  IncentiveRule,
  IncentiveService,
  IncentiveTest,
  IncentiveTestBetween,
  IncentiveTestBloodPressure,
  IncentiveTestReferenceRange,
  IncentiveTestValue,
  isIncentiveBetween,
  isIncentiveBloodPressure,
  isIncentiveFrequency,
  isIncentiveReferenceRange,
  isIncentiveTest,
  isIncentiveValue,
  isIncentiveUpload,
  IncentiveFile,
  isIncentiveWithPoints,
  isIncentiveClickUrl,
  IncentiveWithPoints,
  IncentiveFrequencies,
  IncentiveFrequency
} from '../models/incentives/incentive';
import { IncentiveCategory } from '../models/incentives/incentive-category';
import { User } from '../models/user';
import {
  IncentivePoints,
  IncentiveTrigger,
  UserIncentive
} from '../models/user-incentive';
import { UserRegistration } from '../models/user-registration';
import { UserResult } from '../models/user-result/user-result';
import {
  UserResultTestResult,
  UserResultTestResultRangeStatus
} from '../models/user-result/user-result-test-result';
import { UserTest } from '../models/user-test/user-test';
import { getBmi, getBmiRange } from './get-bmi';
import { getId } from './get-id';
import {
  IsTobaccoUser,
  UserTestCotinineUtil
} from './user-test-util/user-test-cotinine-util';
import { UserTestUtil } from './user-test-util/user-test-util';
import { UserTestBloodPressureUtil } from './user-test-util/user-test-blood-pressure-util';
import { UserUtil } from './user-util';
import {
  getBloodPressureRange,
  isDiastolicBloodPressureTest,
  isSystolicBloodPressureTest
} from './blood-pressure-util';
import { UserTestRange } from '../models/user-test/user-test-range';
import { toMap } from './to-map';
import { DateUtil } from './date-util';
import { ServiceType } from '../models/event-service/service-type';

export class IncentiveUtil {
  /**
   * If the given event service has an incentive program with tiered points,
   * this function will return the relevant incentive tier for the current date.
   * Otherwise, it will return undefined.
   */
  public static getRelevantIncentiveTier(params: {
    eventService: EventService;
    date: Date;
  }): IncentiveTier | undefined {
    const { eventService, date } = params;

    // Not sure why I need to do this, but setHours gets mad if I don't
    const newDate = new Date(date);

    if (!eventService?.startDate || !eventService?.endDate || !newDate) {
      return undefined;
    }

    if (
      !DateUtil.isValidDate(eventService.startDate) ||
      !DateUtil.isValidDate(eventService.endDate)
    ) {
      return undefined;
    }

    const serviceEndDate = DateUtil.convertFromString(eventService.endDate);
    const serviceStartDate = DateUtil.convertFromString(eventService.startDate);

    // Set time to 00:00:00
    newDate.setHours(0, 0, 0, 0);
    serviceEndDate?.setHours(0, 0, 0, 0);
    serviceStartDate?.setHours(0, 0, 0, 0);

    // Convert dates to milliseconds
    const dateInMs = newDate?.getTime() || 0;
    const serviceEndDateInMs = serviceEndDate?.getTime() || 0;
    const serviceStartDateInMs = serviceStartDate?.getTime() || 0;

    // If the date is outside the service start and end date, return undefined
    if (
      !dateInMs ||
      dateInMs < serviceStartDateInMs ||
      dateInMs > serviceEndDateInMs
    ) {
      return undefined;
    }

    const useIncentiveTiers =
      eventService?.incentives?.rewardMethod === IncentiveRewardMethod.TIERED;

    if (useIncentiveTiers) {
      const incentiveTiers = eventService?.incentives?.incentiveTiers || [];

      const relevantTier = incentiveTiers.reduce(
        (latestTier, incentiveTier) => {
          if (
            !incentiveTier?.endDate ||
            !DateUtil.isValidDate(incentiveTier.endDate)
          ) {
            return latestTier;
          }

          const endDate = DateUtil.convertFromString(incentiveTier.endDate);
          const latestTierDate = latestTier?.endDate
            ? DateUtil.convertFromString(latestTier.endDate)
            : undefined;

          endDate?.setHours(0, 0, 0, 0);
          latestTierDate?.setHours(0, 0, 0, 0);

          const endDateInMs = endDate?.getTime() || 0;
          const latestTierEndDateInMs = latestTierDate?.getTime() || 0;

          if (dateInMs > latestTierEndDateInMs && dateInMs <= endDateInMs) {
            return incentiveTier;
          }

          return latestTier;
        },
        {} as IncentiveTier
      );

      return relevantTier?.endDate ? relevantTier : undefined;
    }

    return undefined;
  }

  /**
   * Given an incentive tier index
   */
  public static getIncentiveTierStartDate(params: {
    tier: number;
    eventService: EventService;
  }): string | undefined {
    const { tier, eventService } = params;

    const incentiveTiers = eventService?.incentives?.incentiveTiers;

    if (
      !incentiveTiers?.length ||
      typeof tier !== 'number' ||
      tier <= 0 ||
      tier > incentiveTiers.length
    ) {
      return undefined;
    }

    const tiersMap = toMap({ entities: incentiveTiers, key: 'tier' });

    if (tier === 1) {
      return eventService.startDate;
    }

    const lastEndDate = tiersMap[`${tier - 1}`]?.endDate;

    if (lastEndDate && DateUtil.isValidDate(lastEndDate)) {
      const endDate = DateUtil.convertFromString(lastEndDate);

      if (endDate) {
        endDate?.setDate(endDate.getDate() + 1);

        return DateUtil.convertToString(endDate);
      }
    }

    return undefined;
  }

  // Given an IncentiveService, return IncentivePoints to add to a UserIncentive
  public static getIncentivePointsForService(params: {
    incentive: IncentiveService;
    incentiveTier?: IncentiveTier;
    completedDate?: Date;
  }): Partial<IncentivePoints> | undefined {
    const { incentive, incentiveTier, completedDate } = params;

    if (!incentive) {
      return undefined;
    }

    return {
      incentive: getId(incentive),
      points: incentive.points || 0,
      category: incentive.category,
      completedDate: completedDate || new Date(),
      incentiveName: incentive.name,
      triggerType: incentive.triggerType,
      tier: incentiveTier ? incentiveTier.tier : undefined,
      awarded: true
    };
  }

  public static getUserTestById(params: {
    userResult: UserResult;
    incentive: IncentiveTest;
    userTests: Record<string, UserTest>;
  }) {
    const { userResult, incentive, userTests } = params;

    /** Pass map of user tests, use lab result code as key of map, find labTest based on result code and see if incentive has matching id to response */

    if (
      !userResult ||
      !incentive ||
      !userTests ||
      !isIncentiveTest(incentive)
    ) {
      return undefined;
    }

    const resultTests = Object.values(
      UserTestUtil.getFlatUserResultTests({ userResult }) || {}
    );

    return (resultTests || []).find((resultTest) => {
      const result: UserTest = userTests[resultTest?.resultCode || ''];

      if (!result) {
        return false;
      }

      return [
        getId(incentive.offsiteTestId),
        getId(incentive.onsiteTestId),
        getId(incentive.providerTestId)
      ].includes(getId(result));
    });
  }

  /** Returns a specific incentivePoint object or array of incentivePoints from the array using the incentive as a reference */
  public static getIncentivePointsByIncentive(params: {
    incentive: Incentive;
    userIncentive: UserIncentive;
  }): IncentivePoints[] | undefined {
    const { incentive, userIncentive } = params;

    if (!incentive || !userIncentive) {
      return undefined;
    }

    const incentivePointsMap = toMap({
      entities: userIncentive.incentivePoints,
      key: 'incentive'
    });

    return incentivePointsMap[getId(incentive)];
  }

  /** *Not sure if we're already doing this somewhere  */
  public static getIncentivePointsById(params: {
    userIncentive: UserIncentive;
    id: string | IncentivePoints;
  }) {
    const { userIncentive, id } = params;

    if (!userIncentive || !id) {
      return undefined;
    }

    return (userIncentive.incentivePoints || []).find(
      (ip) => getId(ip) === getId(id)
    );
  }

  /**
   * Check first against the testId, but if the testId did not exist, we check
   * against the resultCode
   */
  public static getTestById(params: {
    testId: string | Pick<DbDocument<string>, '_id'>;
    resultCode?: string;
    userTests: Record<string, UserTest>;
  }): UserTest {
    const { userTests, testId, resultCode } = params;
    const userTestArray = Object.values(userTests);

    return userTestArray.find(
      (userTest) =>
        getId(userTest) === getId(testId) ||
        userTest?.labResultCode === resultCode
    );
  }

  public static getBetweenIncentivePoints(params: {
    incentive: IncentiveTestBetween;
    incentiveTest: UserResultTestResult;
    // Improvement Check parameters
    userTests?: Record<string, UserTest>;
    currentResult?: UserResult;
    prevResult?: UserResult;
    user?: User;
  }) {
    const {
      incentive,
      incentiveTest,
      currentResult,
      prevResult,
      userTests,
      user
    } = params;

    if (!incentive || !incentiveTest) {
      return undefined;
    }

    const incentiveValue =
      typeof incentiveTest?.value === 'number'
        ? incentiveTest?.value
        : Number(incentiveTest?.value);

    const isBetweenValues =
      incentiveValue <= incentive?.testMaxValue &&
      incentiveValue >= incentive?.testValue;

    if (
      !isBetweenValues &&
      incentive.improvementEnabled &&
      typeof incentive.improvementPercent === 'number'
    ) {
      const improvementPoints = this.getImprovementPoints({
        currentResult,
        userTests,
        prevResult,
        incentive,
        user,
        userResultTestResult: incentiveTest
      });

      if (improvementPoints) {
        return improvementPoints;
      }
    }

    return {
      incentive: getId(incentive),
      category: incentive.category,
      incentiveName: incentive.name,
      points: isBetweenValues ? incentive.points : 0,
      triggerType: incentive.triggerType,
      awarded: isBetweenValues,
      completedDate: new Date(),
      value: incentiveTest?.value,
      userTest: getId(incentiveTest.userTest)
    } as IncentivePoints;
  }

  // TODO: This should be renamed to handle self reported tests/values, not just BP, since that is what this is being built for
  public static getBPTestValuePoints(params: {
    incentive: IncentiveTestValue;
    userResult: UserResult;
    userTests: Record<string, UserTest>;
    userRegistration: UserRegistration;
  }) {
    const { incentive, userResult, userRegistration, userTests } = params;

    if (!incentive || !userResult) {
      return undefined;
    }

    const incentiveTest = this.getUserTestById({
      userResult,
      incentive,
      userTests
    });

    const bloodPressureResult =
      UserTestBloodPressureUtil.getBloodPressureResults({
        userResult,
        userRegistration
      });

    let bloodPressureValue: string | number = undefined;

    if (
      isSystolicBloodPressureTest({ resultCode: incentiveTest?.resultCode }) ||
      incentiveTest?.resultCode?.includes(EHS_BP_SYS_RESULT_CODE)
    ) {
      bloodPressureValue = bloodPressureResult?.systolic?.value;
    }

    if (
      isDiastolicBloodPressureTest({ resultCode: incentiveTest?.resultCode }) ||
      incentiveTest?.resultCode?.includes(EHS_BP_DIA_RESULT_CODE)
    ) {
      bloodPressureValue = bloodPressureResult?.diastolic?.value;
    }

    if (!bloodPressureValue) {
      return undefined;
    }

    const awarded = this.isAwardedIncentiveRule({
      incentiveValue: incentive.testValue,
      rule: incentive.rule,
      testValue: bloodPressureValue
    });

    return {
      incentive: getId(incentive),
      category: incentive.category,
      incentiveName: incentive.name,
      completedDate: new Date(),
      points: awarded ? incentive.points : 0,
      triggerType: incentive.triggerType,
      awarded,
      value: bloodPressureValue,
      userTest: getId(incentiveTest?.userTest)
    } as IncentivePoints;
  }

  public static getSmokerPoints(params: {
    incentive: IncentiveTestValue;
    userResult: UserResult;
    userRegistration: UserRegistration;
  }) {
    const { incentive, userResult, userRegistration } = params;

    if (!incentive || !userResult) {
      return undefined;
    }

    // Check for results created by data entry and not lab results
    if (
      userResult.createdAt === userResult.updatedAt &&
      userRegistration.serviceType === ServiceType.ONSITE
    ) {
      return undefined;
    }

    const resultValue = UserTestCotinineUtil.isTobaccoUser({
      userResult,
      userRegistration
    });

    const resultsPending = resultValue === IsTobaccoUser.PENDING;

    const isSmoker = resultValue === IsTobaccoUser.YES;

    return {
      incentive: getId(incentive),
      category: incentive.category,
      incentiveName: incentive.name,
      completedDate: new Date(),
      points: !isSmoker && !resultsPending ? incentive.points : 0,
      triggerType: incentive.triggerType,
      awarded: resultsPending ? null : !isSmoker,
      value: resultsPending ? 'Pending' : isSmoker ? 'Positive' : 'Negative'
    } as IncentivePoints;
  }

  /**  This function is the parent to any function that is not based on test results, or services such as Self-Reported, url-click, and admin-validated */
  public static getNonTestIncentivePoints(params: {
    incentive: Incentive;
    // Only One of the following should be passed
    file: IncentiveFile;
    textValue?: string;
    completedDate?: string;
    incentiveTier?: IncentiveTier;
  }) {
    const { incentive, file, textValue, completedDate, incentiveTier } = params;

    if (isIncentiveUpload(incentive)) {
      return incentive.triggerType === IncentiveTrigger.ADMIN_VALIDATE
        ? this.createAdminValidationPoints({
            incentive,
            file,
            textValue,
            completedDate,
            incentiveTier
          })
        : this.createSelfReportedPoints({
            incentive,
            file,
            textValue,
            completedDate,
            incentiveTier
          });
    }

    if (isIncentiveClickUrl(incentive)) {
      return this.createSelfServicePoints({
        incentive,
        incentiveTier
      });
    }

    // Move functionality for Url CLick here
    return undefined;
  }

  /**
   *
   * Used for creating incentive points through user triggered events
   * such as url links
   */
  public static createSelfServicePoints(params: {
    incentive: IncentiveWithPoints;
    recordDateRange?: { startDate: string; endDate: string };
    totalActivityCount?: number;
    healthDataSync?: boolean;
    incentiveTier?: IncentiveTier;
  }) {
    const {
      incentive,
      recordDateRange,
      totalActivityCount,
      healthDataSync,
      incentiveTier
    } = params;

    if (!incentive || !isIncentiveWithPoints(incentive)) {
      return undefined;
    }

    const hasRecordDateRange =
      DateUtil.isValidDate(recordDateRange?.startDate) &&
      DateUtil.isValidDate(recordDateRange?.endDate);

    const completionDate = DateUtil.convertFromString(
      recordDateRange?.startDate
    );

    return {
      incentive: getId(incentive),
      category: incentive.category,
      incentiveName: incentive.name,
      completedDate: completionDate || new Date(),
      recordDateRange: hasRecordDateRange ? recordDateRange : undefined,
      points: incentive.points,
      triggerType: incentive.triggerType,
      awarded: true,
      tier: incentiveTier ? incentiveTier.tier : undefined,
      healthDataSync: healthDataSync || false,
      value: totalActivityCount
    } as IncentivePoints;
  }

  public static createSelfReportedPoints(params: {
    incentive: IncentiveAdminValidation;

    file?: IncentiveFile;
    // Text box text, only required for buttonType of text_box, but can be passed if upload
    textValue?: string;

    completedDate?: string;
    incentiveTier?: IncentiveTier;
  }) {
    const { incentive, file, textValue, completedDate, incentiveTier } = params;

    if (
      !incentive ||
      !IncentiveUtil.isValidFileIncentive({
        files: file,
        textValue,
        buttonType: incentive.buttonType
      })
    ) {
      return undefined;
    }

    if (file) {
      file.uploadDate = new Date();
    }

    let submitDate = new Date();

    if (completedDate && DateUtil.isValidDate(completedDate)) {
      submitDate = DateUtil.convertFromString(completedDate);
    }

    return {
      incentive: getId(incentive),
      category: incentive.category,
      incentiveName: incentive.name,
      completedDate: submitDate,
      points: incentive.points,
      triggerType: incentive.triggerType,
      awarded: true,
      uploads: file ? [file] : [],
      tier: incentiveTier ? incentiveTier.tier : undefined,
      textValue
    } as IncentivePoints;
  }

  public static isValidFileIncentive(params: {
    files?: FileList | IncentiveFile;
    textValue?: string;
    buttonType?: ButtonType;
  }) {
    const { files, textValue, buttonType } = params;

    // No value being passed and cant check if button_only button type
    if (!files && !textValue && !buttonType) {
      return false;
    }

    // No need to check for file or text value
    if (buttonType === ButtonType.BUTTON_ONLY) {
      return true;
    }

    // Standard text box with a text value being passed
    if (buttonType === ButtonType.TEXT_BOX && textValue) {
      return true;
    }

    // If there is some file object passed...
    if (files) {
      const fileListLength = (files as FileList).length;

      //...it is only invalid if its an empty FileList
      if (fileListLength && fileListLength <= 0) {
        return false;
      }

      return true;
    }

    //If all else fails
    return false;
  }

  public static createAdminValidationPoints(params: {
    incentive: IncentiveAdminValidation;

    file?: IncentiveFile;
    // Text box text, only required for buttonType of text_box, but can be passed if upload
    textValue?: string;

    completedDate?: string;
    incentiveTier?: IncentiveTier;
  }) {
    const { incentive, file, textValue, completedDate, incentiveTier } = params;

    if (
      !incentive ||
      !IncentiveUtil.isValidFileIncentive({
        files: file,
        textValue,
        buttonType: incentive.buttonType
      })
    ) {
      return undefined;
    }

    if (file) {
      file.uploadDate = new Date();
    }

    let submitDate = new Date();

    if (completedDate && DateUtil.isValidDate(completedDate)) {
      submitDate = DateUtil.convertFromString(completedDate);
    }

    return {
      incentive: getId(incentive),
      category: incentive.category,
      incentiveName: incentive.name,
      completedDate: submitDate,
      points: incentive.points,
      triggerType: incentive.triggerType,
      awarded: true,
      uploads: file ? [file] : [],
      tier: incentiveTier ? incentiveTier.tier : undefined,
      textValue
    } as IncentivePoints;
  }

  public static getBloodPressurePoints(params: {
    incentive: IncentiveTestBloodPressure;
    userResult: UserResult;
    userTests: Record<string, UserTest>;
    user: User;
    userRegistration: UserRegistration;
  }) {
    const { incentive, userResult, userRegistration, user, userTests } = params;

    if (!incentive || !userResult || !userRegistration || !user || !userTests) {
      return undefined;
    }

    if (
      userRegistration.serviceType !== ServiceType.PROVIDER_HEALTH &&
      !userRegistration.isResulted
    ) {
      return undefined;
    }

    // Check Lab Results or Registration for bp (TODO: Add support for HRA)
    const bloodPressureResults =
      UserTestBloodPressureUtil.getBloodPressureResults({
        userResult,
        userRegistration
      });

    // If blood pressure doesn't exist in test results, user result or user registration, the incentive is not completed
    if (!bloodPressureResults) {
      return undefined;
    }

    const age = UserUtil.getAge(user);
    const bloodPressureSystolicRange = UserTestUtil.getUserTestRange({
      userTest: userTests[EHS_BP_SYS_RESULT_CODE],
      age,
      gender: user.gender
    });

    const bloodPressureDiastolicRange = UserTestUtil.getUserTestRange({
      userTest: userTests[EHS_BP_DIA_RESULT_CODE],
      age,
      gender: user.gender
    });

    let rangePoints: number;

    const rangeType = getBloodPressureRange({
      bloodPressureSystolic: {
        userResultTestResult: bloodPressureResults.systolic
      },
      bloodPressureSystolicRange,
      bloodPressureDiastolic: {
        userResultTestResult: bloodPressureResults.diastolic
      },
      bloodPressureDiastolicRange
    });

    switch (rangeType) {
      case BloodPressureRange.HIGH:
        rangePoints = incentive.highRisk;
        break;
      case BloodPressureRange.NORMAL:
        rangePoints = incentive.inRange;
        break;
      case BloodPressureRange.PREHYPERTENSIVE:
        rangePoints = incentive.outOfRange;
        break;
      default:
        rangePoints = 0;
        break;
    }

    return {
      incentive: getId(incentive),
      category: incentive.category,
      incentiveName: incentive.name,
      points: rangePoints,
      triggerType: incentive.triggerType,
      awarded: rangePoints > 0,
      completedDate: new Date(),
      value:
        (bloodPressureResults?.systolic?.value || '') +
        ' / ' +
        (bloodPressureResults?.diastolic?.value || '')
    } as IncentivePoints;
  }

  public static isBmiIncentive(params: {
    incentive: IncentiveTest;
    userTests: Record<string, UserTest>;
  }) {
    const { incentive, userTests } = params;
    const labCorpBmi = userTests[LABCORP_BMI_RESULT_CODE];
    const ehsBmi = userTests[EHS_BMI_RESULT_CODE];

    return !![
      getId(incentive.offsiteTestId),
      getId(incentive.onsiteTestId),
      getId(incentive.providerTestId)
    ].find((id) => id === getId(labCorpBmi) || id === getId(ehsBmi));
  }

  public static getBMIPoints(params: {
    incentive: IncentiveTestReferenceRange | IncentiveTestValue;
    userResult: UserResult;
    userRegistration: UserRegistration;
    // Improvement parameters
    prevResult?: UserResult;
    userTests?: Record<string, UserTest>;
    user?: User;
  }) {
    const {
      incentive,
      userResult,
      prevResult,
      user,
      userTests,
      userRegistration
    } = params;

    if (!incentive || !userResult) {
      return undefined;
    }

    if (
      userRegistration.serviceType !== ServiceType.PROVIDER_HEALTH &&
      !userRegistration.isResulted
    ) {
      return undefined;
    }

    const resultTests =
      UserTestUtil.getFlatUserResultTests({ userResult }) || {};

    const height =
      userResult?.height ||
      resultTests['4200']?.value ||
      resultTests['101148']?.value;
    const weight =
      userResult?.weight ||
      resultTests['4201']?.value ||
      resultTests['101149']?.value;

    if (!height || !weight) {
      return undefined;
    }

    const bmi = resultTests['4205']?.value || getBmi({ height, weight });
    const bmiRange = getBmiRange(bmi);

    let rangePoints: number;

    let awarded = false;

    if (isIncentiveReferenceRange(incentive)) {
      switch (bmiRange) {
        case BmiRange.UNDERWEIGHT:
        case BmiRange.OBESE:
          rangePoints = incentive.highRisk;
          break;
        case BmiRange.HEALTHY_WEIGHT:
          rangePoints = incentive.inRange;
          break;
        case BmiRange.OVER_WEIGHT:
          rangePoints = incentive.outOfRange;
          break;
        default:
          rangePoints = 0;
          break;
      }
    }

    awarded = rangePoints > 0;

    if (isIncentiveValue(incentive)) {
      awarded = this.isAwardedIncentiveRule({
        incentiveValue: incentive.testValue,
        rule: incentive.rule,
        testValue: bmi
      });

      if (awarded) {
        rangePoints = incentive.points;
      }
    }

    // If requirement was not met, check if there was improvement, if enabled
    if (
      !awarded &&
      incentive.improvementEnabled &&
      typeof incentive.improvementPercent === 'number'
    ) {
      const improvementPoints = this.getImprovementPoints({
        currentResult: userResult,
        prevResult,
        incentive,
        userTests,
        user,
        userResultTestResult:
          ({ value: bmi } as UserResultTestResult) ||
          resultTests['4205']?.value,
        bmi: true
      });

      if (improvementPoints) {
        return improvementPoints;
      }
    }

    return {
      incentive: getId(incentive),
      category: incentive.category,
      incentiveName: incentive.name,
      points: rangePoints || 0,
      triggerType: incentive.triggerType,
      awarded,
      completedDate: new Date(),
      value: bmi.toFixed(2)
    } as IncentivePoints;
  }

  public static getRangeIncentivePoints(params: {
    incentive: IncentiveTestReferenceRange;
    incentiveTest: UserResultTestResult;
    user: User;
    userTest: UserTest;
    // Improvement parameters
    currentResult?: UserResult;
    prevResult?: UserResult;
    userTests?: Record<string, UserTest>;
  }) {
    const {
      incentive,
      incentiveTest,
      user,
      userTest,
      currentResult,
      prevResult,
      userTests
    } = params;

    if (!incentive || !incentiveTest || !user || !userTest) {
      return undefined;
    }
    const age = UserUtil.getAge(user);
    const userTestRange = UserTestUtil.getUserTestRange({
      userTest,
      age,
      gender: user.gender
    });

    if (!userTestRange) {
      return undefined;
    }

    const rangeType = UserTestUtil.getRange({
      userResultTestResult: incentiveTest,
      userTestRange
    });

    let rangePoints = 0;

    switch (rangeType) {
      case 'highRisk':
        rangePoints = incentive.highRisk;
        break;
      case 'inRange':
        rangePoints = incentive.inRange;
        break;
      case 'outOfRange':
        rangePoints = incentive.outOfRange;
        break;
      default:
        rangePoints = 0;
        break;
    }

    // If full points weren't achieved, check if there was improvement, if enabled
    if (
      rangeType !== 'inRange' &&
      incentive.improvementEnabled &&
      typeof incentive.improvementPercent === 'number'
    ) {
      const improvementPoints = this.getImprovementPoints({
        currentResult,
        prevResult,
        incentive,
        userTests,
        user,
        userResultTestResult: incentiveTest
      });

      if (improvementPoints) {
        return improvementPoints;
      }
    }

    return {
      incentive: getId(incentive),
      category: incentive.category,
      incentiveName: incentive.name,
      points: rangePoints,
      triggerType: incentive.triggerType,
      awarded: rangePoints > 0,
      completedDate: new Date(),
      value: incentiveTest?.value,
      userTest: getId(incentiveTest.userTest)
    } as IncentivePoints;
  }

  /**
   * Primary Utility function for creating/rewarding incentive points.
   * takes userResult and returns the incentive points that the user has.
   * This utility will need to be called every time the userResults are updated.
   * For example, if the lab sent the cholesterol to be 100 then user can get 20 points,
   * but if the admin changes the value to 120, it might change the incentive of the user to 10 points.
   * The utility should iterate against the userResult tests, height, weight, bmi, tobacco usage, etc...
   * and determine if any of those are candidates for incentives.
   */
  public static getIncentiveTestValue(params: {
    incentive: Incentive;
    userResult: UserResult;
    user: User;
    userRegistration?: UserRegistration;
    userTests: Record<string, UserTest>;
    previousResult?: UserResult;
  }): IncentivePoints | undefined {
    const {
      incentive,
      userResult,
      previousResult,
      userTests,
      user,
      userRegistration
    } = params;

    if (!isIncentiveTest(incentive)) {
      return undefined;
    }

    // Handle BMI Incentive Here
    // ToDo: add ability to accept Between Type Incentives
    if (this.isBmiIncentive({ incentive, userTests })) {
      return this.getBMIPoints({
        incentive: incentive as IncentiveTestReferenceRange,
        userResult,
        userRegistration,
        prevResult: previousResult,
        user,
        userTests
      });
    }

    // Blood Pressure Trigger Type
    if (isIncentiveBloodPressure(incentive)) {
      return this.getBloodPressurePoints({
        incentive,
        user,
        userResult,
        userRegistration,
        userTests
      });
    }

    // * This is the UserResultTestResult and should be renamed
    const incentiveTest = this.getUserTestById({
      userResult,
      incentive,
      userTests
    });

    if (
      !incentiveTest &&
      incentive.triggerType !== IncentiveTrigger.IS_SMOKER
    ) {
      return undefined;
    }

    if (isIncentiveBetween(incentive)) {
      return this.getBetweenIncentivePoints({
        incentive,
        incentiveTest,
        user,
        currentResult: userResult,
        prevResult: previousResult,
        userTests
      });
    }

    if (isIncentiveReferenceRange(incentive)) {
      const userTest = this.getTestById({
        testId: getId(incentiveTest?.userTest),
        resultCode: incentiveTest?.resultCode,
        userTests
      });

      return this.getRangeIncentivePoints({
        incentive,
        incentiveTest,
        user,
        userTest,
        currentResult: userResult,
        prevResult: previousResult,
        userTests
      });
    }

    if (isIncentiveValue(incentive)) {
      // Individual Blood Pressure Test Value Incentive
      if (
        isSystolicBloodPressureTest({
          resultCode: incentiveTest?.resultCode
        }) ||
        isDiastolicBloodPressureTest({ resultCode: incentiveTest?.resultCode })
      ) {
        return this.getBPTestValuePoints({
          incentive,
          userRegistration,
          userResult,
          userTests
        });
      }

      // If Incentive is Smoker, check if user is is a smoker, if yes, award no points
      if (incentive.triggerType === IncentiveTrigger.IS_SMOKER) {
        return this.getSmokerPoints({
          incentive,
          userRegistration,
          userResult
        });
      }

      // Otherwise return incentivePoints for regular value-based incentive
      const awarded = this.isAwardedIncentiveRule({
        incentiveValue: incentive.testValue,
        rule: incentive.rule,
        testValue: incentiveTest?.value
      });

      // If value is not awarded, Check if Improvement from last result in desired direction, if enabled
      if (
        !awarded &&
        incentive.improvementEnabled &&
        typeof incentive.improvementPercent === 'number'
      ) {
        const improvementPoints = this.getImprovementPoints({
          currentResult: userResult,
          userTests,
          prevResult: previousResult,
          incentive,
          user,
          userResultTestResult: incentiveTest
        });

        if (improvementPoints) {
          return improvementPoints;
        }
      }

      return {
        incentive: getId(incentive),
        category: incentive.category,
        incentiveName: incentive.name,
        completedDate: new Date(),
        points: awarded ? incentive.points : 0,
        triggerType: incentive.triggerType,
        awarded,
        value: incentiveTest?.value,
        userTest: getId(incentiveTest?.userTest)
      } as IncentivePoints;
    }

    return undefined;
  }

  /**
   * Check if the user should be awarded the incentive based on the incentive rule.
   * Some tests have a value of <1 or >100 etc.. For those use cases we will consider
   * the value to be the number without the < or the >
   */
  private static isAwardedIncentiveRule({
    rule,
    incentiveValue,
    testValue
  }: {
    rule: IncentiveRule;
    incentiveValue: number;
    testValue?: number | string;
  }) {
    // This is the parsed test value since it can be a string of < or > 1
    const value = UserTestUtil.getNumberValue(testValue);

    if (value === null || value === undefined) {
      return false;
    }

    switch (rule) {
      case IncentiveRule.EQUALS:
        return value === incentiveValue;
      case IncentiveRule.GREATER_THAN:
        return value > incentiveValue;
      case IncentiveRule.GREATER_THAN_OR_EQUAL:
        return value >= incentiveValue;
      case IncentiveRule.LESS_THAN:
        return value < incentiveValue;
      case IncentiveRule.LESS_THAN_OR_EQUAL:
        return value <= incentiveValue;
      default:
        return false;
    }
  }

  public static calculateTotalIncentivePoints(params: {
    eventService: EventService;
    userIncentive: UserIncentive;
  }) {
    const { eventService, userIncentive } = params;

    if (!eventService || !userIncentive) {
      return 0;
    }

    const totalPoints: Record<IncentiveCategory | string, number> = (
      userIncentive.incentivePoints || []
    ).reduce((totals, incentive) => {
      totals[incentive.category]
        ? (totals[incentive.category] += incentive?.points || 0)
        : (totals[incentive.category] = incentive?.points || 0);

      return totals;
    }, {});

    // ? Not sure if this logic is current, may need to replace maxPoints from eventService with below function
    const categoryTotals = eventService.incentives?.categoryMaxPoints || {};

    Object.keys(totalPoints).forEach((key) => {
      if (totalPoints[key] > categoryTotals[key]) {
        totalPoints[key] = categoryTotals[key];
      }
    });

    const totalSum = Object.values(totalPoints).reduce(
      (acc: number, curr: number) => acc + curr,
      0
    );

    // We need the max available, if no EventServiceIncentive, return sum of totals
    const maxPoints = eventService.incentives?.maxPoints || totalSum;

    if (totalSum >= maxPoints) {
      return maxPoints;
    } else {
      return totalSum;
    }
  }

  public static sumIncentivePoints(incentivesArray: IncentivePoints[]) {
    if (!incentivesArray?.length) {
      return 0;
    }

    return incentivesArray.reduce((total, incentive) => {
      return (total += incentive?.points || 0);
    }, 0);
  }

  /**
   * Calculate the max available points based on available incentives and the sum of the highest amount of points
   * Ideally the max available should come from the eventService.incentives object, this is a redundant total
   * @param incentives
   */
  public static calculateMaxAvailable(incentives: Incentive[]) {
    if (!incentives?.length) {
      return undefined;
    }

    return incentives.reduce((total, incentive) => {
      if (incentive.category === IncentiveCategory.ALT_QUALIFIER) {
        return total;
      }

      if (isIncentiveWithPoints(incentive)) {
        return total + incentive.points || 0;
      }

      if (
        isIncentiveReferenceRange(incentive) ||
        isIncentiveBloodPressure(incentive)
      ) {
        return (
          total +
          (incentive.inRange || incentive.outOfRange || incentive.highRisk || 0)
        );
      }

      return total;
    }, 0);
  }

  /**
   * Calculate total available points for each category in included.
   */
  public static calculateCategoryMaxAvailable(
    incentives: Incentive[]
  ): Record<IncentiveCategory, number> {
    if (!incentives.length) {
      return undefined;
    }

    return incentives.reduce(
      (total, incentive) => {
        const category = incentive.category;

        if (category === IncentiveCategory.ALT_QUALIFIER) {
          return total;
        }

        let points: number;

        if (isIncentiveWithPoints(incentive)) {
          points = incentive.points;
        } else if (
          isIncentiveReferenceRange(incentive) ||
          isIncentiveBloodPressure(incentive)
        ) {
          points =
            incentive.inRange || incentive.outOfRange || incentive.highRisk;
        } else points = 0;

        return { ...total, [category]: total[category] + points || points };
      },
      {} as Record<IncentiveCategory, number>
    );
  }

  public static getMaxAvailableIncentivePoints(incentive: Incentive) {
    if (isIncentiveFrequency(incentive)) {
      return incentive.points * incentive.maxAllowed;
    }

    if (isIncentiveWithPoints(incentive)) {
      return incentive.points;
    }

    if (
      isIncentiveReferenceRange(incentive) ||
      isIncentiveBloodPressure(incentive)
    ) {
      return incentive.inRange || incentive.outOfRange || incentive.highRisk;
    }

    return undefined;
  }

  public static getCategoryTotals(userIncentive: UserIncentive) {
    if (!userIncentive || !userIncentive.incentivePoints.length) {
      return undefined;
    }

    return userIncentive.incentivePoints.reduce(
      (total, incentive) => {
        const category = incentive.category;

        return {
          ...total,
          [category]: total[category] + incentive.points || incentive.points
        };
      },
      {} as Record<IncentiveCategory, number>
    );
  }

  /**
   * EventServiceUtil that takes an eventService and returns whether or not incentive is enabled for a given eventService
   * pass user, in user, check against personType is in EventService.incentive.participants, and check if incentiveType is participants or outcomes, return false if not
   */
  public static isIncentiveEnabled(params: {
    eventService: EventService;
    user: User;
  }) {
    const { eventService, user } = params;

    if (!eventService || !user) {
      return undefined;
    }

    if (!eventService?.incentives) {
      return false;
    }

    if (eventService.incentives.showIncentives === false) {
      return false;
    }

    if (eventService.incentives.incentiveType !== IncentiveType.EHS) {
      return false;
    }

    if (
      !(eventService.incentives.participants || []).includes(user.personType)
    ) {
      return false;
    }

    return true;
  }

  public static isIncentiveGroup(params: {
    eventService: EventService;
    group: IncentiveGrouping;
  }) {
    const { eventService, group } = params;

    if (!eventService) {
      return false;
    }

    // If no incentiveGroup is on the event service incentive,and checking if its HEALTHY_METRICS,return true as HEALTHY_METRICS its the default
    if (
      group === IncentiveGrouping.HEALTHY_METRICS &&
      !eventService.incentives?.incentiveGroup
    ) {
      return true;
    }

    return eventService.incentives?.incentiveGroup === group;
  }

  public static getIncentiveAwardStatus(params: {
    incentive: Incentive;
    incentivePoints: IncentivePoints[];
  }): IncentiveAwardStatus | undefined {
    const { incentive, incentivePoints } = params;

    if (!incentivePoints?.length) {
      return undefined;
    }

    const isAwarded = incentivePoints[0]?.awarded;

    const maxPoints = this.getMaxAvailableIncentivePoints(incentive);

    const earnedPoints = Math.max(...incentivePoints.map((ip) => ip.points));

    const matchingIncentives = this.getIncentivePointsByIncentiveId({
      incentive,
      incentivePoints
    });

    const completedCount = matchingIncentives.length || 0;

    // When the user did not get awarded the points we show unearned
    if (isAwarded === false) {
      return IncentiveAwardStatus.UNEARNED;
    }

    if (isIncentiveFrequency(incentive) && completedCount > 0) {
      return IncentiveAwardStatus.FULL;
    }

    /** If Incentive has 0 points (such as Biometric or HRA), but is completed, it should count as awarded */
    if (maxPoints === 0 || earnedPoints >= maxPoints) {
      return IncentiveAwardStatus.FULL;
    }

    if (!earnedPoints || (earnedPoints <= 0 && maxPoints > 0)) {
      return IncentiveAwardStatus.UNEARNED;
    }

    return IncentiveAwardStatus.PARTIAL;
  }

  /** Function to reorder annual awareness incentives to make sure HRA and Biometrics always come first, if included */
  public static reorderAnnualAwareness(incentives: Incentive[]) {
    const hraIncentives =
      incentives.filter(
        (incentive) => incentive.triggerType === IncentiveTrigger.COMPLETED_HRA
      ) || [];
    const biometricIncentives =
      incentives.filter(
        (incentive) => incentive.triggerType === IncentiveTrigger.COMPLETED_LAB
      ) || [];
    const filteredIncentives = incentives.filter(
      (incentive) =>
        incentive.triggerType !== IncentiveTrigger.COMPLETED_LAB &&
        incentive.triggerType !== IncentiveTrigger.COMPLETED_HRA
    );
    const sortedByPositionIncentives =
      this.reorderByPositionValue(filteredIncentives);

    return [
      ...hraIncentives,
      ...biometricIncentives,
      ...sortedByPositionIncentives
    ].filter((_) => !!_);
  }

  /**
   * Organizes incentives with position values with 1 as priority.
   * Incentives without positions can should not be ordered.
   */
  public static reorderByPositionValue(incentives: Incentive[]) {
    if (incentives?.length === 0) {
      return incentives;
    }

    const incentivesWithPositions = incentives.filter(
      (incentive) => incentive.position
    );
    const incentivesWithoutPositions = incentives.filter(
      (incentive) => !incentive.position
    );

    incentivesWithPositions.sort(
      (incentiveA, incentiveB) => incentiveA.position - incentiveB.position
    );

    return [...incentivesWithPositions, ...incentivesWithoutPositions].filter(
      (_) => !!_
    );
  }

  /** Function to return the sum of the required point amounts for each category on the eventService */
  public static getRequiredIncentivePoints(eventService: EventService): number {
    const pointsMap = eventService.incentives?.categoryMaxPoints;

    if (!pointsMap) {
      return undefined;
    }

    return (Object.entries(pointsMap) || []).reduce((total, [key, value]) => {
      if (key !== IncentiveCategory.ALT_QUALIFIER) {
        total += Number(value);
      }

      return total;
    }, 0);
  }

  /** Function to check if any incentives are specifically required, and if they are, if they've been completed
    @Returns true if there are no required incentives, or if requirements have been met */
  public static checkRequiredIncentives(params: {
    incentives: Incentive[];
    userIncentive: UserIncentive;
  }) {
    const { incentives, userIncentive } = params;

    // Check if there are any required incentives
    const requiredIncentives = (incentives || []).filter(
      (incentive) => incentive.requiredIncentive
    );

    // If there are no required incentives, return true
    if (!requiredIncentives.length) {
      return true;
    }

    // If there are, and there are completed incentives, check if the required incentive exists among completed
    if ((userIncentive?.incentivePoints || []).length) {
      const awardedIncentives = userIncentive.incentivePoints;

      const requirementsMet: Record<string, boolean> =
        requiredIncentives.reduce((map, reqIncentive) => {
          map[getId(reqIncentive)] = !!awardedIncentives.find(
            (ap) => getId(ap.incentive) === getId(reqIncentive)
          );

          return map;
        }, {});

      // If all the values are true, all required incentives have been completed, else, some haven't
      return Object.values(requirementsMet).every(Boolean);
    }

    // No incentives have been completed so requirements are not met
    return false;
  }

  /** This is a temporary helper function to get around problem with incorrect totalPoints on userIncentive  */
  public static getTotalPoints(userIncentive: UserIncentive) {
    if (!userIncentive) {
      return 0;
    }

    const incentiveTotal = this.sumIncentivePoints(
      userIncentive.incentivePoints
    );

    return incentiveTotal;
  }

  /**
   * Function to determine if an incentive has an action for the user, if it does return the link and title to direct the user, or the help message
   */
  public static getIncentiveAction(params: {
    incentive: Incentive;
    category: IncentiveCategory;
    eventService: EventService;
    userIncentive: UserIncentive;
    userRegistrations: UserRegistration[];
    incentivesMap: Record<
      IncentiveCategory,
      { incentive: Incentive; incentivePoints: IncentivePoints[] }[]
    >;
  }) {
    const {
      incentive,
      category,
      eventService,
      userIncentive,
      userRegistrations,
      incentivesMap
    } = params;

    // Incentive frequency max allowed count checked here too
    if (IncentiveUtil.isCompleted({ incentive, category, incentivesMap })) {
      return undefined;
    }

    // Hide action button if incentive has been awarded max allowed of times
    if (isIncentiveFrequency(incentive)) {
      const completedCount = IncentiveUtil.getIncentivePointsByIncentiveId({
        incentivePoints: userIncentive?.incentivePoints,
        incentive
      }).length;

      if (completedCount >= incentive.maxAllowed) {
        return undefined;
      }
    }

    // User has registration but no lab results:
    if (
      IncentiveUtil.isRegistered({ eventService, userRegistrations }) &&
      isIncentiveTest(incentive)
    ) {
      return { message: 'Pending Lab Results' };
    }

    // User has reached required minimum points, no alternative incentive is needed
    if (
      category === IncentiveCategory.ALT_QUALIFIER &&
      IncentiveUtil.getRequiredIncentivePoints(eventService) <=
        IncentiveUtil.getTotalPoints(userIncentive)
    ) {
      return { message: 'No Further Action Needed' };
    }

    if (incentive.buttonType === ButtonType.NONE) {
      return undefined;
    }

    if (
      incentive.buttonType === ButtonType.REGISTER ||
      incentive.triggerType === IncentiveTrigger.COMPLETED_LAB ||
      incentive.triggerType === IncentiveTrigger.COMPLETED_FLU ||
      incentive.triggerType === IncentiveTrigger.LAB_TEST_BETWEEN ||
      incentive.triggerType === IncentiveTrigger.LAB_TEST_RANGE ||
      incentive.triggerType === IncentiveTrigger.LAB_TEST_VALUE
    ) {
      return {
        link: '/register/type',
        title: incentive.buttonName || 'Enroll Now'
      };
    }

    // ToDo @Brenden: If user has HRA in progress, this does not work, need to fetch in progress HRA and direct to it
    if (
      incentive.triggerType === IncentiveTrigger.COMPLETED_HRA ||
      incentive.buttonType === ButtonType.HRA
    ) {
      return {
        link: '/riskAssessment',
        title: incentive.buttonName || "Let's Go!"
      };
    }

    if (
      incentive.triggerType === IncentiveTrigger.FAX_RESULTS ||
      incentive.buttonType === ButtonType.FAX
    ) {
      return { link: '/share', title: incentive.buttonName || 'Send Fax' };
    }

    if (incentive.buttonName && incentive.buttonUrl) {
      return { link: '//' + incentive.buttonUrl, title: incentive.buttonName };
    }

    return undefined;
  }

  /**
   *
   * Returns the maximum number of potential points a user could earn
   * from the list of incentives within a given category
   */
  public static getMaxPotentialPoints(params: {
    category: IncentiveCategory;
    incentives?: Incentive[];
  }) {
    const { incentives, category } = params;

    if (!incentives || !incentives.length) {
      return 0;
    }

    return (incentives || []).reduce((max, incentive) => {
      if (incentive?.category === category) {
        return (max +=
          IncentiveUtil.getMaxAvailableIncentivePoints(incentive) || 0);
      }

      return max;
    }, 0);
  }

  //  Determines if incentive has been completed based off of associated incentivePoints
  public static isCompleted(params: {
    incentive: Incentive;
    category: IncentiveCategory;
    incentivesMap: Record<
      IncentiveCategory,
      { incentive: Incentive; incentivePoints: IncentivePoints[] }[]
    >;
  }) {
    const { incentive, category, incentivesMap } = params;
    const incentiveObj = incentivesMap[category].find((incentives) => {
      if (
        getId(incentives.incentive) === getId(incentive) &&
        isIncentiveFrequency(incentive)
      ) {
        const matchingIncentives = this.getIncentivePointsByIncentiveId({
          incentive,
          incentivePoints: incentives.incentivePoints
        });

        const count = matchingIncentives.length || 0;

        return count >= incentive.maxAllowed;
      }

      return getId(incentives.incentive) === getId(incentive);
    });

    return incentiveObj?.incentivePoints?.length > 0 ? true : false;
  }

  /**
   * Return an object with the first and last dates of a given frequency
   * that a given date would fall into. Returns undefined if frequency or
   * date is missing, string date isn't in yyyy-MM-dd format, or frequency is
   * ONCE
   */
  public static getFrequencyRangeDates(
    frequency: IncentiveFrequencies,
    date: string
  ) {
    if (!frequency || !date || !DateUtil.isValidDate(date)) {
      return undefined;
    }

    const convertedDate = DateUtil.convertFromString(date);

    let startDate: Date;
    let endDate: Date;

    const day = convertedDate.getDay();
    const dayOfMonth = convertedDate.getDate();
    const month = convertedDate.getMonth() + 1;
    const year = convertedDate.getFullYear();

    switch (frequency) {
      case IncentiveFrequencies.ONCE: {
        // Matching incentives is already checked to have a length
        return undefined;
      }
      case IncentiveFrequencies.ONE_DAY:
        startDate = DateUtil.convertFromString(date);
        startDate.setHours(0, 0, 0);
        endDate = DateUtil.convertFromString(date);
        endDate.setHours(23, 59, 59);

        break;
      case IncentiveFrequencies.ONE_WEEK: {
        if (day === 1) {
          startDate = convertedDate;

          endDate = DateUtil.getFutureDate({
            day: 6,
            startingDate: convertedDate,
            zone: 'UTC-6'
          });
        } else {
          //For week frequency, start date is previous monday
          startDate = DateUtil.getFutureDate({
            day: day === 0 ? -6 : 1 - day,
            startingDate: convertedDate,
            zone: 'UTC-6'
          });

          // End date is future sunday or given day if sunday
          endDate = DateUtil.getFutureDate({
            day: day === 0 ? day : 6 - day,
            startingDate: convertedDate,
            zone: 'UTC-6'
          });
        }
        break;
      }
      // Split months in half, first half is always the first 15 days.
      case IncentiveFrequencies.TWO_WEEKS: {
        if (dayOfMonth < 16) {
          startDate = new Date(`${year}-${month}-${1}`);
          endDate = new Date(`${year}-${month}-${15}`);
        } else {
          startDate = new Date(`${year}-${month}-${16}`);

          endDate = DateUtil.getFutureDate({
            startingDate: new Date(`${year}-${month + 1}-${1}`),
            days: -1,
            zone: 'UTC-6'
          });
        }
        break;
      }
      case IncentiveFrequencies.ONE_MONTH: {
        startDate = new Date(`${year}-${month}-${1}`);

        endDate = DateUtil.getFutureDate({
          startingDate: new Date(`${year}-${month + 1}-${1}`),
          days: -1,
          zone: 'UTC-6'
        });
        break;
      }
      case IncentiveFrequencies.QUARTERLY: {
        if (month < 4) {
          startDate = new Date(`${year}-${1}-${1}`);
          endDate = new Date(`${year}-${3}-${31}`);
        } else if (month < 7) {
          startDate = new Date(`${year}-${4}-${1}`);
          endDate = new Date(`${year}-${6}-${31}`);
        } else if (month < 10) {
          startDate = new Date(`${year}-${7}-${1}`);
          endDate = new Date(`${year}-${9}-${30}`);
        } else {
          startDate = new Date(`${year}-${10}-${1}`);
          endDate = new Date(`${year}-${12}-${31}`);
        }
        break;
      }
      default:
        return undefined;
    }

    return { startDate, endDate };
  }

  /**
   * As part of IncentiveFrequencies, check if a given date for a new incentive
   * would conflict with existing incentive points according to the date ranges
   * they occupy.
   *
   * ONCE - incentives cant be entered for the same date
   * ONE_WEEK - incentives cant be entered for the same Mon-Sun week
   * TWO_WEEK - incentives cant be entered for the same 14 day span
   * ONE_MONTH - incentives cant be entered for the same month
   * QUARTERLY - incentives cant be entered for the same 3 month quarter
   */
  public static hasConflictingFrequencyIncentive(params: {
    userIncentive: UserIncentive;
    incentive: Incentive;
    date: string;
  }) {
    const { incentive, userIncentive, date } = params;

    // Cant verify frequency
    if (!incentive || !date) {
      return true;
    }

    // No user incentive so no conflict
    if (!userIncentive?.incentivePoints?.length) {
      return false;
    }

    // Not a frequency incentive then no frequency conflict
    if (
      !isIncentiveFrequency(incentive) &&
      incentive.triggerType !== IncentiveTrigger.COMPLETED_FLU
    ) {
      return false;
    }

    const matchingIncentivePoints = this.getIncentivePointsByIncentiveId({
      incentive,
      incentivePoints: userIncentive.incentivePoints
    });

    // Has user incentive points, but none matching this incentive
    if (!matchingIncentivePoints.length) {
      return false;
    }

    const frequencyDateRanges = this.getFrequencyRangeDates(
      (incentive as IncentiveFrequency).frequency,
      date
    );

    if (!frequencyDateRanges) {
      return true;
    }

    const startRangeDate = frequencyDateRanges.startDate;
    const endRangeDate = frequencyDateRanges.endDate;

    // Look for existing incentive point with completed date that would land
    // in the date range of this incoming incentive's completed date
    const conflict = matchingIncentivePoints.find((incentive) => {
      // If userIncentive has recordDateRange
      if (
        incentive.recordDateRange?.startDate &&
        incentive.recordDateRange?.endDate
      ) {
        const startDate = DateUtil.convertFromString(
          incentive.recordDateRange.startDate
        );
        const endDate = DateUtil.convertFromString(
          incentive.recordDateRange.endDate
        );

        // If incoming date range overlaps at all with a date range of an existing user incentive point
        // its a conflict
        if (
          !(
            (startRangeDate.getTime() < startDate.getTime() &&
              endRangeDate.getTime() < startDate.getTime()) ||
            (startRangeDate.getTime() > endDate.getTime() &&
              endRangeDate.getTime() > endDate.getTime())
          )
        ) {
          return true;
        }

        return false;
      }

      const completedOn = DateUtil.convertTimeZone(
        new Date(incentive.completedDate),
        'UTC'
      );

      const completedOnTime = completedOn.getTime();

      if (
        completedOnTime >= startRangeDate.getTime() &&
        completedOnTime <= endRangeDate.getTime()
      ) {
        return true;
      }

      return false;
    });

    // Time since last incentive earned in day count
    return !!conflict;
  }

  /**
   * Returns unsorted subset of incentive points with an incentive id
   * matching the given incentive. Array should begin with most recent
   * incentive points.
   *
   * Pass awarded only to filter to only awarded incentive points
   */
  public static getIncentivePointsByIncentiveId(params: {
    incentive: Incentive;
    incentivePoints: IncentivePoints[];
    awardedOnly?: boolean;
  }) {
    const { incentive, incentivePoints, awardedOnly } = params;

    if (!incentive || !incentivePoints?.length) {
      return [];
    }

    return (
      incentivePoints?.filter((points) => {
        if (awardedOnly) {
          return getId(incentive) === getId(points.incentive) && points.awarded;
        }

        return getId(incentive) === getId(points.incentive);
      }) || []
    );
  }

  /**
   * Determines if user has an existing registration related to the incentive's eventService
   **/
  public static isRegistered(params: {
    eventService: EventService;
    userRegistrations: UserRegistration[];
  }) {
    const { eventService, userRegistrations } = params;

    return !!(userRegistrations || []).find(
      (userRegistration) =>
        getId(userRegistration.eventService) === getId(eventService)
    );
  }

  /** Determines if the 'improvement' is a positive change or a worsening change, to be used with the getImprovementPercent function */
  public static isImprovement(params: {
    prevResult: UserResultTestResult;
    currResult: UserResultTestResult;
    userTestRange: UserTestRange;
  }) {
    const { prevResult, currResult, userTestRange } = params;

    if (
      typeof prevResult.value !== 'number' ||
      typeof currResult.value !== 'number'
    ) {
      return undefined;
    }

    const range = UserTestUtil.getRangeStatus({
      userResultTestResult: prevResult,
      userTestRange
    });

    if (!range) {
      return undefined;
    }

    if (
      [
        UserResultTestResultRangeStatus.ABOVE_NORMAL,
        UserResultTestResultRangeStatus.ABOVE_NORMAL_PANIC,
        UserResultTestResultRangeStatus.PANIC_HIGH
      ].includes(range)
    ) {
      return prevResult.value > currResult.value;
    }

    if (
      [
        UserResultTestResultRangeStatus.BELOW_NORMAL,
        UserResultTestResultRangeStatus.BELOW_NORMAL_PANIC,
        UserResultTestResultRangeStatus.PANIC_LOW
      ].includes(range)
    ) {
      return prevResult.value < currResult.value;
    }

    // Otherwise range should be normal, and we aren't tracking improvement
    return false;
  }

  /** Determines the percent of variance between current result and their previous result for a specific test.
   * This should be called in each of the helper functions that create an incentivePoints Object wherever points are not awarded.
   * * For range based incentivePoints, this function should be called if the user is to receive less than max points, even if points are to be awarded.
   */
  public static getImprovementPercent(params: {
    currentResult: UserResult;
    prevResult: UserResult;
    userTests: Record<string, UserTest>;
    incentive: IncentiveTest;
    user: User;
    // Height/weight do not use tests so logic needs to be adapted
    bmi?: boolean;
  }) {
    const { currentResult, prevResult, userTests, incentive, user, bmi } =
      params;

    if (!incentive || !currentResult || !prevResult || !userTests || !user) {
      return undefined;
    }

    // Since BMI isn't based on a test, we need a different way to check for improvement
    if (bmi) {
      const currentResultTests =
        UserTestUtil.getFlatUserResultTests({ userResult: currentResult }) ||
        {};
      const prevResultTests =
        UserTestUtil.getFlatUserResultTests({ userResult: prevResult }) || {};

      const prevBMI = getBmi({
        height:
          prevResult.height ||
          prevResultTests['4200']?.value ||
          prevResultTests['101148']?.value,
        weight:
          prevResult.weight ||
          prevResultTests['4201']?.value ||
          prevResultTests['101149']?.value
      });
      const currentBMI = getBmi({
        height:
          currentResult.height ||
          currentResultTests['4200']?.value ||
          currentResultTests['101148']?.value,
        weight:
          currentResult.weight ||
          currentResultTests['4201']?.value ||
          currentResultTests['101149']?.value
      });

      const prevBMIRange = getBmiRange(prevBMI);

      // Check if the BMI is underweight then we need to make sure the current result is higher to be considered improved.
      if (prevBMIRange === BmiRange.UNDERWEIGHT) {
        if (prevBMI < currentBMI) {
          return Number((((currentBMI - prevBMI) / prevBMI) * 100).toFixed(2));
        }

        return undefined;
      }

      // If the user BMI is within range, we then don't need to check for improvement
      if (prevBMIRange === BmiRange.HEALTHY_WEIGHT) {
        return undefined;
      }

      if (prevBMI > currentBMI) {
        return Number((((prevBMI - currentBMI) / prevBMI) * 100).toFixed(2));
      }

      return undefined;
    }

    const prevResultTestResult = this.getUserTestById({
      incentive,
      userResult: prevResult,
      userTests
    });

    const currentResultTestResult = this.getUserTestById({
      incentive,
      userResult: currentResult,
      userTests
    });

    if (!prevResultTestResult || !currentResultTestResult) {
      return undefined;
    }

    const userTest = (Object.values(userTests) || []).find((test) => {
      return [
        getId(incentive.offsiteTestId),
        getId(incentive.onsiteTestId),
        getId(incentive.providerTestId)
      ].includes(getId(test));
    });

    if (!userTest) {
      return undefined;
    }

    const userTestRange = UserTestUtil.getUserTestRange({
      userTest,
      age: UserUtil.getAge(user),
      gender: user.gender
    });

    if (!userTestRange) {
      return undefined;
    }

    // If variance is not an improvement, we do not need to know the variance percent
    if (
      !this.isImprovement({
        currResult: currentResultTestResult,
        prevResult: prevResultTestResult,
        userTestRange
      })
    ) {
      return undefined;
    }

    const prevValue = prevResultTestResult?.value;
    const currentValue = currentResultTestResult?.value;

    if (typeof prevValue !== 'number' || typeof currentValue !== 'number') {
      return undefined;
    }

    return Math.floor(((prevValue - currentValue) / prevValue) * 100);
  }

  public static getImprovementPoints(params: {
    currentResult: UserResult;
    prevResult: UserResult;
    incentive: IncentiveTestValue | IncentiveTestReferenceRange;
    userTests: Record<string, UserTest>;
    user: User;
    userResultTestResult: UserResultTestResult;
    bmi?: boolean;
  }) {
    const {
      currentResult,
      prevResult,
      incentive,
      userTests,
      user,
      bmi,
      userResultTestResult
    } = params;

    if (
      !currentResult ||
      !prevResult ||
      !incentive ||
      !userTests ||
      !user ||
      (!userResultTestResult && !bmi)
    ) {
      return undefined;
    }

    const improvementPercent = this.getImprovementPercent({
      currentResult,
      userTests,
      prevResult,
      incentive,
      user,
      bmi
    });

    const improvementThreshold = incentive.improvementPercent;

    if (
      improvementPercent &&
      (improvementPercent > improvementThreshold ||
        improvementPercent < -improvementThreshold)
    ) {
      let points = 0;

      if (isIncentiveReferenceRange(incentive)) {
        points = incentive.inRange;
      } else if (isIncentiveValue(incentive) || isIncentiveBetween(incentive)) {
        points = incentive.points;
      } else {
        // Not sure how to handle this case, if its even possible
        console.log('wrong incentive type passed');

        return undefined;
      }

      return {
        incentive: getId(incentive),
        category: incentive.category,
        incentiveName: incentive.name,
        completedDate: new Date(),
        points,
        triggerType: incentive.triggerType,
        awarded: true,
        improved: true,
        improvementPercentage: Math.abs(improvementPercent),
        value: userResultTestResult?.value,
        userTest: getId(userResultTestResult?.userTest)
      } as IncentivePoints;
    }

    // If no improvement return undefined so points can be awarded as they normally would
    return undefined;
  }

  /**
   * Determines whether to show or hide points for a given incentive.
   * It requires the following parameters:
   * - incentive: The incentive object to check.
   * - eventService: (optional) The event service object associated with the incentive.
   *
   * It returns a boolean value indicating whether to show or hide the points.
   */
  public static showPoints(params: {
    incentive: Incentive | IncentiveTestValue | IncentiveTestReferenceRange;
    eventService?: EventService;
  }) {
    const { incentive, eventService } = params;

    if (incentive?.category !== 'challenges') {
      return true;
    }

    if (!incentive.challengesEndDate && !eventService) {
      return true;
    }

    // Only hide points if 'withhold' toggle is on
    if (isIncentiveFrequency(incentive) && incentive.withholdPoints) {
      let endDate: Date;
      const today = DateUtil.convertFromString(DateUtil.getToday());

      // Default to service end date
      if (eventService) {
        endDate = DateUtil.convertFromString(eventService.endDate);

        if (endDate <= today) {
          return true;
        }
      }

      // If incentive has a challengesEndDate, use that instead
      if (incentive.challengesEndDate) {
        // If incentive has a last report date, use that instead
        endDate = incentive.lastReportDate
          ? DateUtil.convertFromString(incentive.lastReportDate)
          : DateUtil.convertFromString(incentive.challengesEndDate);
      }

      return endDate <= today;
    }

    return true;
  }
}
