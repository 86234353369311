import { ObjectId } from 'mongodb';
import {
  EmailTemplates,
  Company,
  DbDocument,
  EventService,
  User
} from '@common';
import {
  isNoShowTemplateData,
  NoShowEmailTemplateData,
  EventCanceledTemplateData,
  isEventCanceledData,
  isHRAReminderTemplateData,
  HRAReminderTemplateData,
  OffsiteReminderTemplateData,
  isOffsiteReminderTemplateData,
  IncentiveReminderTemplateData,
  isIncentiveReminderTemplateData,
  FaxConfirmTemplateData
} from '../templates_data';
import {
  HPFReminderTemplateData,
  isHPFReminderTemplateData
} from '../templates_data/health-provider-form-template-data';
import {
  isPasswordResetEmailTemplateData,
  PasswordResetEmailTemplateData
} from '../templates_data/password-reset-email-template-data';
import {
  ForgotUsernameEmailTemplateData,
  isForgotUsernameEmailTemplateData
} from '../templates_data/forgot-username-email-template-data';

/**
 * Base document for user email audits
 */
export interface UserEmailAuditBase extends DbDocument {
  // The type of email
  emailTemplate: EmailTemplates;
  // The email address it was send to
  email: string;
  // User the email was send to
  user: string | ObjectId | User;
  // The related company
  company: string | ObjectId | Company;
}
/**
 * Enum of currently supported email templates that have user email audits
 */
export enum USER_EMAIL_AUDIT_TYPES {
  HRA_REMINDER = EmailTemplates.HRA_REMINDER,
  EVENT_CANCELLED = EmailTemplates.EVENT_CANCELLED,
  NO_SHOW = EmailTemplates.NO_SHOW,
  HPF_REMINDER = EmailTemplates.HPF_REMINDER,
  PASSWORD_RESET = EmailTemplates.PASSWORD_RESET,
  FAX_CONFIRM = EmailTemplates.FAX_CONFIRM,
  FORGOT_USERNAME = EmailTemplates.FORGOT_USERNAME,
  OFFSITE_REMINDER = EmailTemplates.OFFSITE_REMINDER,
  INCENTIVE_REMINDER = EmailTemplates.INCENTIVE_REMINDER,
  INCENTIVE_HEALTHY_METRICS_REMINDER = EmailTemplates.INCENTIVE_HEALTHY_METRICS_REMINDER
}

export interface PasswordResetUserEmailAudit extends UserEmailAuditBase {
  emailTemplate: EmailTemplates.PASSWORD_RESET;
  dynamicTemplateData: PasswordResetEmailTemplateData;
}

export const isPasswordResetEmailAudit = (
  audit: UserEmailAudit
): audit is PasswordResetUserEmailAudit =>
  audit?.emailTemplate === EmailTemplates.PASSWORD_RESET &&
  isPasswordResetEmailTemplateData(
    (audit as PasswordResetUserEmailAudit).dynamicTemplateData
  );

export interface ForgotUsernameUserEmailAudit extends UserEmailAuditBase {
  emailTemplate: EmailTemplates.FORGOT_USERNAME;
  dynamicTemplateData: ForgotUsernameEmailTemplateData;
}

export const isForgotUsernameEmailAudit = (
  audit: UserEmailAudit
): audit is ForgotUsernameUserEmailAudit =>
  audit?.emailTemplate === EmailTemplates.FORGOT_USERNAME &&
  isForgotUsernameEmailTemplateData(
    (audit as ForgotUsernameUserEmailAudit).dynamicTemplateData
  );

/**
 * HRA reminder email audit
 */
export interface HRAReminderUserEmailAudit extends UserEmailAuditBase {
  emailTemplate: EmailTemplates.HRA_REMINDER;

  // The related event service
  eventService: string | ObjectId | EventService;

  dynamicTemplateData: HRAReminderTemplateData;
}

export const isHRAReminderEmailAudit = (
  audit: UserEmailAudit
): audit is HRAReminderUserEmailAudit =>
  audit?.emailTemplate === EmailTemplates.HRA_REMINDER &&
  isHRAReminderTemplateData(
    (audit as HRAReminderUserEmailAudit).dynamicTemplateData
  );

/**
 * HPF reminder email audit
 */
export interface HPFReminderUserEmailAudit extends UserEmailAuditBase {
  emailTemplate: EmailTemplates.HPF_REMINDER;

  // The related event service
  eventService: string | ObjectId | EventService;

  dynamicTemplateData: HPFReminderTemplateData;
}

export const isHPFReminderEmailAudit = (
  audit: UserEmailAudit
): audit is HPFReminderUserEmailAudit =>
  audit?.emailTemplate === EmailTemplates.HPF_REMINDER &&
  isHPFReminderTemplateData(
    (audit as HPFReminderUserEmailAudit).dynamicTemplateData
  );

/**
 * Event canceled email audit
 */
export interface CanceledEventUserEmailAudit extends UserEmailAuditBase {
  emailTemplate: EmailTemplates.EVENT_CANCELLED;
  // The related event service
  eventService: string | ObjectId | EventService;

  dynamicTemplateData: EventCanceledTemplateData;
}

export const isCanceledEventEmailAudit = (
  audit: UserEmailAudit
): audit is CanceledEventUserEmailAudit =>
  audit?.emailTemplate === EmailTemplates.EVENT_CANCELLED &&
  isEventCanceledData(
    (audit as CanceledEventUserEmailAudit).dynamicTemplateData
  );

/**
 * No show email audit
 */
export interface NoShowUserEmailAudit extends UserEmailAuditBase {
  emailTemplate: EmailTemplates.NO_SHOW;
  // The related event service
  eventService: string | ObjectId | EventService;

  dynamicTemplateData: NoShowEmailTemplateData;
}

export const isNoShowEmailAudit = (
  audit: UserEmailAudit
): audit is NoShowUserEmailAudit =>
  audit?.emailTemplate === EmailTemplates.NO_SHOW &&
  isNoShowTemplateData((audit as NoShowUserEmailAudit).dynamicTemplateData);

/**
 * Offsite reminder email audit
 */
export interface OffsiteReminderUserEmailAudit extends UserEmailAuditBase {
  emailTemplate: EmailTemplates.OFFSITE_REMINDER;

  // The related event service
  eventService: string | ObjectId | EventService;

  dynamicTemplateData: OffsiteReminderTemplateData;
}

export const isOffsiteReminderEmailAudit = (
  audit: UserEmailAudit
): audit is OffsiteReminderUserEmailAudit =>
  audit?.emailTemplate === EmailTemplates.OFFSITE_REMINDER &&
  isOffsiteReminderTemplateData(
    (audit as OffsiteReminderUserEmailAudit).dynamicTemplateData
  );

export interface IncentiveReminderUserEmailAudit extends UserEmailAuditBase {
  emailTemplate:
    | EmailTemplates.INCENTIVE_REMINDER
    | EmailTemplates.INCENTIVE_HEALTHY_METRICS_REMINDER;
  // The related event service
  eventService: string | ObjectId | EventService;

  dynamicTemplateData: IncentiveReminderTemplateData;
}

export const isIncentiveReminderEmailAudit = (
  audit: UserEmailAudit
): audit is IncentiveReminderUserEmailAudit =>
  audit?.emailTemplate === EmailTemplates.INCENTIVE_REMINDER &&
  isIncentiveReminderTemplateData(
    (audit as IncentiveReminderUserEmailAudit).dynamicTemplateData
  );

export interface FaxConfirmEmailAudit extends UserEmailAuditBase {
  emailTemplate: EmailTemplates.FAX_CONFIRM;
  dynamicTemplateData: FaxConfirmTemplateData;
}

export const isFaxConfirmEmailAudit = (
  audit: UserEmailAudit
): audit is FaxConfirmEmailAudit =>
  audit?.emailTemplate === EmailTemplates.FAX_CONFIRM;

export type UserEmailAudit =
  | NoShowUserEmailAudit
  | CanceledEventUserEmailAudit
  | HRAReminderUserEmailAudit
  | UserEmailAuditBase
  | HPFReminderUserEmailAudit
  | OffsiteReminderUserEmailAudit
  | IncentiveReminderUserEmailAudit
  | PasswordResetUserEmailAudit
  | ForgotUsernameUserEmailAudit
  | FaxConfirmEmailAudit;
